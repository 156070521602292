import { PackingMaterial } from '.';
import { OrderLine } from './order-line.viewmodel';

export class PackingMaterials {

    constructor(
        public locationId: string,
        public position: number,
        public quantity: number,
        public packingMaterial: PackingMaterial
    ) {

    }
  }
