<div class="put-away-content">
    <h2>{{ 'put-away.orderline.title' | translate }}</h2>
    <div class="put-away">
        <h3>{{ orderLineItem.caption }}</h3>
        <div class="description">{{ orderLineItem.description | striphtml }}</div>
        <div class="image">
            <img width="260px" height="364px" src="../../../../../assets/images/put-away-image.png">
            <div class="tote">{{ orderLineItem.barcode }}</div>
        </div>
    </div>
    <div class="submit">
        <button type="submit" (click)="putAway();">{{ 'put-away.orderline.button' | translate }}</button>
    </div>
</div>