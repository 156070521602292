import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { FooterMenuItem, FooterService } from '../../services/footer.service';
import { HeaderService } from '../../services/header.service';

@Component({
    selector: 'app-login-content',
    templateUrl: './login-content.component.html',
    styleUrls: ['./login-content.component.scss']
})
export class LoginContentComponent implements OnInit, AfterViewInit, OnDestroy {
    rightMenuItems: FooterMenuItem[];

    setFooterSubscription: Subscription;

    constructor(
        private footerService: FooterService,
        private router: Router) { }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.setFooterSubscription = this.footerService.onSetFooter()
            .subscribe(footer => {
                this.rightMenuItems = footer.rightMenuItems;
            });
    }

    ngOnDestroy(): void {
        this.setFooterSubscription.unsubscribe();
    }
}
