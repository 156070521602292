<div [ngClass]="isScannerMode && !disableScanner ? 'scanner-mode' : 'keyboard-mode'">
    <div class="barcode-scanner" [ngClass]="{
        'scanning': scannerStatus === ScannerStatus.Scanning || scannerStatus === ScannerStatus.Validating,
        'confirmed': scannerStatus === ScannerStatus.Confirmed,
        'not-found': scannerStatus === ScannerStatus.NotFound}">
        <div class="barcode-scanner-header">
            <div class="left">
                <h2 *ngIf="scannerStatus === ScannerStatus.Default">{{ 'scanning.' + getScanningTypeValue() + '.captions.scan-to-start' | translate }}</h2>
                <h2 *ngIf="scannerStatus === ScannerStatus.Scanning">{{ 'scanning.' + getScanningTypeValue()  + '.captions.scanning' | translate }}</h2>
                <h2 *ngIf="scannerStatus === ScannerStatus.Validating">{{ 'scanning.' + getScanningTypeValue()  + '.captions.validating' | translate }}</h2>
                <h2 *ngIf="scannerStatus === ScannerStatus.Confirmed">{{ 'scanning.' + getScanningTypeValue()  + '.captions.confirmed' | translate }}</h2>
                <h2 *ngIf="scannerStatus === ScannerStatus.NotFound">{{ 'scanning.' + getScanningTypeValue()  + '.captions.not-found' | translate }}</h2>
            </div>
            <div class="right" *ngIf="!disableScanner">
                <button [ngClass]="{
                    'selected': isScannerMode === true}"(click)="toggleKeyboard();"
                    [disabled]="scannerStatus === ScannerStatus.Scanning">{{ 'scanning.common.captions.use-scanner' | translate }}</button>
                <button [ngClass]="{
                    'selected': isScannerMode !== true}"
                    (click)="toggleKeyboard();"
                    [disabled]="scannerStatus === ScannerStatus.Scanning">{{ 'scanning.common.captions.use-keyboard' | translate }}</button>
            </div>
        </div>
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="barcodeSubmit()">
            <div class="barcode-border">
                
                    <div class="top-left-corner corner"></div>
                    <div class="top-right-corner corner"></div>
                    <div class="bottom-left-corner corner"></div>
                    <div class="bottom-right-corner corner"></div>
                    <div class="barcode-container">
                        <input id="barcode" formControlName="barcode"
                            [readonly]="(!disableScanner && (isScannerMode || scannerStatus === ScannerStatus.Confirmed) ? 'true' : false) ||
                                disableInput"
                            type="text" placeholder="0000-0000-0000-0000" class="barcode"
                            (keydown)="barcodeChange()" (click)="barcodeClick()">
                        <div class="t-scan-box"></div>   
                        <div class="b-scan-box"></div>
                    </div>
            </div>
        </form>
        <span class="message" [ngClass]="scannerStatus === ScannerStatus.NotFound ? 'message-error-icon' : ''">{{ 'scanning.' + getScanningTypeValue()  + '.captions.description' | translate }}</span>
    </div> 
</div>