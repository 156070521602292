import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseApiService {

    account: any;

    constructor(public http: HttpClient,
                public msalService: MsalService) {
        this.account = this.msalService?.getAccount();
    }

    get<T>(url: string): Observable<T> {
        const headers = new HttpHeaders().append('owd-api-key', environment.packApiKey);
        return this.http.get<T>(url, { headers });
    }

    post<T>(url: string, body: object): Observable<T> {
        const headers = new HttpHeaders().append('owd-api-key', environment.packApiKey);
        return this.http.post<T>(url, body, { headers });
    }

    put<T>(url: string, body: object): Observable<T> {
        const headers = new HttpHeaders().append('owd-api-key', environment.packApiKey);
        return this.http.put<T>(url, body, { headers });
    }

    patch<T>(url: string, body: object): Observable<T> {
        const headers = new HttpHeaders().append('owd-api-key', environment.packApiKey);
        return this.http.patch<T>(url, body, { headers });
    }

    delete<T>(url: string): Observable<T> {
        const headers = new HttpHeaders().append('owd-api-key', environment.packApiKey);
        return this.http.delete<T>(url, { headers });
    }

    setAuthData(request: any) {
        request.appUserId = this.account?.accountIdentifier;
        request.appUserName = this.account?.name;
    }
}
