import { Issue } from './issue.model';
import { OrderLine } from './order-line.viewmodel';
import { PackingMaterials } from './packing-materials.model';

export class Product {

    public issuesSet?: Issue[];
    public isEditable?: boolean;

    constructor(
        public accountId: string,
        public id: string,
        // tslint:disable-next-line: variable-name
        public number: string,
        public name: string,
        public quantity: number,
        public description: string,
        public upc: string,
        public manufacturerNumber: string,
        public state: string,
        public serializedFlag: boolean,
        public serializationMode: string,
        public nonStockItemFlag: boolean,
        public expirableFlag: boolean,
        public fragileFlag: boolean,
        public insertFlag: boolean,
        public scannableFlag: boolean,
        public setupCompleteFlag: boolean,
        public lotRequiredFlag: boolean,
        public lotPattern: string,
        public packingInstructions: string,
        public tags: string[],
        public customsOriginCountryCode: string,
        public customsDescription: string,
        public customsDeclaredValue: number,
        public customsValueCurrency: string,
        public customsHarmonizedSystemCode: string,
        public price: number,
        public priceCurrency: string,
        public weight: number,
        public weightUom: string,
        public dimensionsHeight: number,
        public dimensionsLength: number,
        public dimensionsWidth: number,
        public dimensionsUom: string,
        public hazmatUnNumber: string,
        public hazmatHazardClass: string,
        public color: string,
        public size: string,
        public quantityUom: string,
        public quantityCase: number,
        public quantityInnerPack: number,
        public quantityPallet: number,
        public photoUrl: string,
        public autobagProhibitedFlag: boolean,
        public packingMaterials: PackingMaterials[],
        public billingOptions?: BillingOptions
    ) {

    }
  }

export class BillingOptions {
    constructor(
        public bulkyPackFlag: boolean,
        public bulkyPackOverrideFlag: boolean,
        public bulkyPickOverrideFee: number
    ) {

    }
  }
