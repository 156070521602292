import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { BroadcastService, MsalService} from '@azure/msal-angular';
import { AuthService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private authService: AuthService,
              private msalService: MsalService,
              private translate: TranslateService
) {
  this.translate.addLangs(['en']);
  this.translate.use('en');
  this.translate.setDefaultLang('en');
  }

  isAuthorized(): boolean {
    return this.authService.isLoggedIn;
  }
}
