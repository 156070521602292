import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';


export interface IAccountApiService {
    getAccounts(name: string);
    getAccount(accountId: string);
    getAccountSynchronousCall(accoutnId: string);
}

export const ACCOUNT_API_SERVICE = new InjectionToken<IAccountApiService>('IAccountApiService');
