<div class="barcode-scanner">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="barcodeSubmit()">
        <div class="left">
            <input id="barcode" formControlName="barcode" type="text"
                (click)="searchClick();" (focusout)="searchFocusOut()"
                value="0000-0000-0000-0000" placeholder="0000-0000-0000-0000" class="barcode">
        </div>
        <div class="right">
            <a (click)="toggleKeyboard();"></a>
        </div>
    </form>
</div>
