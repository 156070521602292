import { OrderLine } from './order-line.viewmodel';

export class ProductHash {

    constructor(
        public lookupValue: string,
        public productId: string
    ) {

    }
  }
