export class Tote {
    constructor(
        public locationId: string,
        public state: string,
        public name: string,
        public weight: ToteWeight
    ) {
    }
  }

export class ToteWeight {
    constructor(
      public value: number,
      public unit: string
    ) {
    }
  }

export class ToteHeaderLabel {
  constructor(
    public value: string,
    public caption: string
  ) {
  }
}
