import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class BarcodeService {

  constructor() {}

  protected barcodeScannedSubject = new Subject<string>();

  public barcodeScanned(buttonId: string) {
    this.barcodeScannedSubject.next(buttonId);
  }

  public onBarcodeScanned(): Observable<string> {
    return this.barcodeScannedSubject.asObservable();
  }
}
