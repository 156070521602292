import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BarcodeService } from 'src/app/core/services/barcode.service';
import * as onScan from 'onscan.js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-barcode-scanner-light',
  templateUrl: './barcode-scanner-light.component.html',
  styleUrls: ['./barcode-scanner-light.component.scss']
})
export class BarcodeScannerLightComponent implements OnInit, OnDestroy {
  form: FormGroup;

  barCodeScannedSubscription: Subscription;

  @Output() barcodeScanned = new EventEmitter<string>();
  @Output() searchClicked = new EventEmitter<void>();
  @Output() searchFocusOutEvent = new EventEmitter<void>();

  constructor(public fb: FormBuilder,
              private barcodeService: BarcodeService,
              private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      barcode: new FormControl('', [Validators.required])
    });

    this.barCodeScannedSubscription = this.barcodeService.onBarcodeScanned()
    .subscribe(barcode => {
      this.form.get('barcode').setValue(barcode);
      this.barcodeScanned.emit(barcode);
      this.form.get('barcode').setValue('');
    });
  }

  ngOnDestroy(): void {
    this.barCodeScannedSubscription.unsubscribe();
  }


  searchClick() {
    this.searchClicked.emit();
  }

  searchFocusOut() {
    this.searchFocusOutEvent.emit();
  }

  barcodeSubmit() {
    if (this.form.valid) {
      const barcode = this.form.get('barcode').value;
      // added 5 spacess, to allow scanning for short barcodes. Will be trimmed after scan operation.
      onScan.simulate(document, barcode + '     ');
    }
  }

  toggleKeyboard() {
    document.getElementById('barcode').focus();
  }
}
