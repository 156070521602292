import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PackingMaterial } from 'src/app/shared-kernel/models';
import { PackingMaterialApiService } from 'src/app/shared-kernel/services/packing-material/packing-material.api.service';
@Component({
  selector: 'app-generate-lpn',
  templateUrl: './generate-lpn.component.html',
  styleUrls: ['./generate-lpn.component.scss']
})
export class GenerateLpnComponent implements OnInit, AfterViewInit {

  packingMaterials: PackingMaterial[] = null;

  constructor(private activatedRoute: ActivatedRoute,
              private packingMaterialService: PackingMaterialApiService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router) {
            }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }
}
