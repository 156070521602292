import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { Box } from 'src/app/shared-kernel/models/box.model';

@Injectable({ providedIn: 'root'})
export class BoxService {

  constructor() {}

  private boxScannedSubject = new Subject<Box>();

  public getCurrentBoxJSON(): string {
    return sessionStorage.getItem('box');
  }

  public getCurrentBox(): Box {
    return JSON.parse(sessionStorage.getItem('box'));
  }

  public boxScanned(box: Box) {
    sessionStorage.setItem('box', JSON.stringify(box));
    this.boxScannedSubject.next(box);
  }

  public onBoxScanned(): Observable<Box> {
    return this.boxScannedSubject.asObservable();
  }
}
