import { AccountApiService } from 'src/app/shared-kernel/services';
import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrderApiService } from '../../services';
import * as onScan from 'onscan.js';
import { BarcodeService } from 'src/app/core/services/barcode.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductApiService } from '../../services/product/product.api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
  providers: [ProductApiService]
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isScannerMode = true;
  scannerStatus: ScannerStatus = ScannerStatus.Default;

  barCodeScannedSubscription: Subscription;

  _scanningType: ScanningType;
  get scanningType(): ScanningType {
      if (!this._scanningType) {
        throw new Error('Attribute "ScanningType" is required');
      }
      return this._scanningType;
  }
  @Input() set scanningType(value: ScanningType) {
      this._scanningType = value;
  }

  @Input() disableInput: boolean;

  @Input() disableScanner: boolean;

  @Input() accountId: string;

  @Output() barcodeScanned = new EventEmitter<string>();

  public get ScannerStatus(): typeof ScannerStatus {
    return ScannerStatus;
  }

  constructor(public fb: FormBuilder,
              private orderService: OrderApiService,
              private translate: TranslateService,
              private barcodeService: BarcodeService,
              private productService: ProductApiService,
              private accountService: AccountApiService,
              private router: Router) {}

  ngOnInit(): void {
    this.initForm();

    if (this.disableScanner) {
      this.isScannerMode = false;
    }

    this.barCodeScannedSubscription = this.barcodeService.onBarcodeScanned()
      .subscribe(barcode => {
        this.validateBarcode(barcode);
    });
  }

  ngOnDestroy(): void {
    this.barCodeScannedSubscription.unsubscribe();
  }

  initForm() {
    this.form = this.fb.group({
      barcode: new FormControl('', [Validators.required])
    });
  }

  barcodeChange() {
    if (this.scannerStatus !== ScannerStatus.Default && this.isScannerMode !== true) {
      this.scannerStatus = ScannerStatus.Default;
    }
  }

  barcodeClick() {
    if (this.scannerStatus === ScannerStatus.NotFound && this.isScannerMode !== true) {
      this.scannerStatus = ScannerStatus.Default;
      this.form.get('barcode').setValue('');
    }
  }

  barcodeSubmit() {
    if (this.form.valid) {
      const barcode = this.form.get('barcode').value;
      // added 5 spacess, to allow scanning for short barcodes. Will be trimmed after scan operation.
      onScan.simulate(document, barcode + '     ');
    } else {
      this.showErrorMessage();
    }
  }

  validateBarcode(barcode) {
      if (this.scanningType.toString() === 'ScanningType.Order') {
        this.scannerStatus = ScannerStatus.Validating;
        this.orderService.getCachedOrder(barcode.slice(0, 36), true).subscribe(result => {
          if (result.length > 0) {
            this.scannerStatus = ScannerStatus.Confirmed;
            if (barcode.slice(0, 36) === result[0].id) {
              this.barcodeScanned.emit(barcode);
            } else {
              this.barcodeScanned.emit(result[0].id);
            }
            this.accountService.getCachedAccount(result[0].accountId, true);
          } else {
            this.showErrorMessage();
          }
        },
        (err: HttpErrorResponse) => {
          this.showErrorMessage();
        });
      } else if (this.scanningType.toString() === 'ScanningType.Box') {
        this.scannerStatus = ScannerStatus.Confirmed;
        this.barcodeScanned.emit(this.form.get('barcode').value);
      } else if (this.scanningType.toString() === 'ScanningType.Product') {
        this.scannerStatus = ScannerStatus.Validating;
        this.productService.getProduct(barcode, this.accountId).subscribe(result => {
          if (result) {
            this.scannerStatus = ScannerStatus.Confirmed;
            this.barcodeScanned.emit(barcode);
          } else {
            this.showErrorMessage();
          }
        },
        (err: HttpErrorResponse) => {
          this.showErrorMessage();
        });
      }
  }

  showErrorMessage() {
    this.scannerStatus = ScannerStatus.NotFound;
    this.translate.get('scanning.common.captions.try-again').subscribe(value =>
      this.form.get('barcode').setValue(value));
    document.getElementById('barcode')?.blur();
  }

  toggleKeyboard() {
    this.isScannerMode = !this.isScannerMode;
    this.scannerStatus = ScannerStatus.Default;

    this.form.get('barcode').setValue('');
    if (this.isScannerMode) {
      this.scannerStatus = ScannerStatus.Default;
    } else {
      document.getElementById('barcode').focus();
    }
  }

  getScanningTypeValue() {
    return this.scanningType ? this.scanningType.toString().split('.')[1].toLowerCase() : '';
  }
}

export enum ScannerStatus {
  Default = 1,
  Scanning = 2,
  Confirmed = 3,
  NotFound = 4,
  Validating = 5
}

export enum ScanningType {
  Order = 1,
  Box = 2,
  Product = 3
}
