import { OrderLine } from './order-line.viewmodel';

export class Customer {

    constructor(
      public id: string,
      public name: string,
      public state: string,
      public notes: string
    ) {

    }
  }
