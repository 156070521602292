<header *ngIf="displayMode !== DisplayMode.None">
    <div class="top" *ngIf="notificationMenuItem"
        [ngClass]="{
            'center': notificationMenuItem.align === MenuTextAlign.Center,
            'green': notificationMenuItem.color === MenuColor.Green,
            'red': notificationMenuItem.color === MenuColor.Red
            }">
        <div class="notification-message">{{ notificationMenuItem?.data | translate }} <strong>{{ notificationMenuItem?.caption | translate }}</strong></div>
        <a (click)="closeNotificationHeader();"></a>
    </div>
    <div class="top" *ngIf="topMenuItem"
        [ngClass]="{'center': topMenuItem.align === MenuTextAlign.Center}">
        {{ topMenuItem.caption | translate }}
        <a (click)="closeTopHeader();" *ngIf="topMenuItem.showCloseButton"></a>
    </div>
    <div class="default-mode" *ngIf="displayMode === DisplayMode.Default && (leftMenuItems || rightMenuItems)">
        <div class="left" *ngFor = "let menu of leftMenuItems">
            <a href="/"  *ngIf="menu.type === HeaderMenuItemType.Logo">
                <div class="logo">
                    <div class="content">
                        <div class="content-data">{{ menu.data | translate }}</div>
                    </div>
                </div>
            </a>
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.TimeClock">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ getTimeClock() }}</div>
                </div>
            </div>
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.Boxes">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ getBoxes() }}</div>
                </div>
            </div>
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.Orders">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ getOrders() }}</div>
                </div>
            </div>
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.Element">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <a href="{{ menu.url }}" *ngIf="menu.url"><div class="content-data">{{ menu.data | translate }}</div></a>
                    <div *ngIf="!menu.url" class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div>
            <div class="sub-element" *ngIf="menu.type === HeaderMenuItemType.SubElement">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div>
            <div class="element problem-order-details" *ngIf="menu.type === HeaderMenuItemType.ProblemOrderDetails">
                <div class="status">
                    <span>{{ menu.caption }}</span>
                </div>
                <button [ngClass]="{'selected': isShowProblemOrderDetailsSelected}"
                    (click)="showProblemOrderDetails(menu.id)"></button>
            </div> 
        </div>
        <div class="right" *ngFor = "let menu of rightMenuItems">
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.Element">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div>
            <a class="dropdown user-name" *ngIf="menu.type === HeaderMenuItemType.UserName">
                <div class="element">
                    <div class="content">
                        <div class="content-caption right">{{ 'header.menu-items.howdy' | translate }}</div>
                        <div class="content-data right">{{ getUserName() }}</div>
                    </div>
                </div>
                <div class="dropdown-content">
                    <a class="separator" href="/">Home</a>
                    <a class="separator" href="/mvp/create">Create Instructions</a>
                    <a href="/mvp">Find Instructions</a>
                </div>  
            </a>
            <div class="sub-element" *ngIf="menu.type === HeaderMenuItemType.SubElement">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div>
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.BenchMark">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data"><app-timer [time]="+menu.data"></app-timer></div>
                </div>
            </div> 
            <div class="element problem-order" *ngIf="menu.type === HeaderMenuItemType.ProblemOrder">
                <a class="problem-order" (click)="navigate(menu.url)"></a>
                <!-- <a (click)="navigate(menu.url)" class="content-data">{{ problemOrdersCount }}</a> -->
            </div>
            <div class="element commercial-invoice" *ngIf="menu.type === HeaderMenuItemType.CommercialInvoice">
                <a class="commercial-invoice" href="{{menu.url}}"></a>
                <!-- <a (click)="navigate(menu.url)" class="content-data">{{ problemOrdersCount }}</a> -->
            </div>
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.ReportProblemOrder">
                <a class="report-problem-order" (click)="navigate(menu.url)"></a>
            </div>  
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.LogOut">
                <a class="logout" (click)="logOut()"></a>
            </div>  
            <div class="close" *ngIf="menu.type === HeaderMenuItemType.Close">
                <a (click)="navigate(menu.url)"></a>
            </div>       
        </div>
    </div>
    <div class="search-mode" *ngIf="displayMode === DisplayMode.Search || displayMode === DisplayMode.DefaultWhite">
        <div class="left" *ngFor = "let menu of leftMenuItems">
            <div class="back" *ngIf="menu.type === HeaderMenuItemType.Back">
                <a href="{{ menu.url }}"></a>
            </div>
            <div class="title" *ngIf="menu.type === HeaderMenuItemType.Title">
                <div class="content" [ngClass]="{'data': menu.data}">
                    <div class="content-data" *ngIf="menu.data"><a (click)="navigate(menu.url)">{{ menu.data | translate }}</a></div>
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                </div>
            </div>
        </div>
        <div class="middleMenu"  *ngFor = "let menu of middleMenuItems">
            <div class="search" *ngIf="menu.type === HeaderMenuItemType.Search || menu.type === HeaderMenuItemType.SearchAccount">
                <input [ngClass]="{
                    'focus': isSerachBoxReseted === false}" autocomplete="off" id='searchInput' type="text"
                    (focusin)="searchFocusIn();"
                    (focusout)="searchFocusOut();"
                    (click)="searchClick($event);" (keyup)="searchChange(menu, $event);" placeholder="Search">
                <div class="reset" *ngIf="isSerachBoxReseted === false">
                    <a (mousedown)="searchReset();"></a>
                </div>
                <div class="drop-down-menu header" *ngIf="accounts && accounts.length > 0 && menu.type === HeaderMenuItemType.SearchAccount && isSerachBoxReseted === false">
                    {{ accounts.length }} Results found
                </div>
                <div class="drop-down-menu" *ngIf="accounts && accounts.length > 0 && menu.type === HeaderMenuItemType.SearchAccount && isSerachBoxReseted === false">
                    <div class="menu-item" *ngFor="let account of accounts">
                        <a (click)="selectAccount(menu.id, account.id, account.name)">{{ account.name }}.</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="right" *ngFor = "let menu of rightMenuItems">
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.Element">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div>
            <div class="sub-element" *ngIf="menu.type === HeaderMenuItemType.SubElement">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div>
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.ReportProblemOrder">
                <a class="report-problem-order-white" (click)="navigate(menu.url)"></a>
            </div> 
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.LogOut">
                <a class="logout" (click)="logOut()"></a>
            </div>  
            <div class="skip" *ngIf="menu.type === HeaderMenuItemType.Skip">
                <a (click)="clickDefaultButton(menu.id)">{{ menu.caption | translate }}</a>
            </div>
            <div class="state" *ngIf="menu.type === HeaderMenuItemType.State">
                <div class="toggle">
                    <a (click)="setState(State.Active)" [ngClass]="{'selected': state === State.Active}">Active</a>
                    <a (click)="setState(State.Draft)" [ngClass]="{'selected': state === State.Draft}">Draft</a>
                </div>
            </div>
            <div class="locale" *ngIf="menu.type === HeaderMenuItemType.Locale">
                <div class="toggle">
                    <span *ngFor = "let locale of locales">
                        <a (click)="setLocale(locale)" 
                            [ngClass]="{'selected': 
                                (selectedLocale && selectedLocale.id === locale.id) ||
                                (!selectedLocale && menu.data === locale.id)
                                }">
                            {{ locale.locale.toUpperCase() }}</a>
                    </span>
                </div>
            </div>
            <div class="close" *ngIf="menu.type === HeaderMenuItemType.Close">
                <a (click)="navigate(menu.url)"></a>
            </div>
        </div>
    </div>
    <div class="title-mode" *ngIf="displayMode === DisplayMode.Title">
        <div *ngFor = "let menu of leftMenuItems; let i = index">
            <div *ngIf='i === 0'>
                <div class="back" *ngIf="menu.type === HeaderMenuItemType.Back">
                    <a href="{{ menu.url }}"></a>
                </div>
                <div class="title">
                    {{ title | translate }}
                </div>
            </div>
        </div>
        <div class=" right-panel">
            <div class="right" *ngFor = "let menu of rightMenuItems">
                <div class="element" *ngIf="menu.type === HeaderMenuItemType.Element">
                    <div class="content">
                        <div class="content-caption">{{ menu.caption | translate }}</div>
                        <div class="content-data">{{ menu.data | translate }}</div>
                    </div>
                </div>
                <div class="sub-element" *ngIf="menu.type === HeaderMenuItemType.SubElement">
                    <div class="content">
                        <div class="content-caption">{{ menu.caption | translate }}</div>
                        <div class="content-data">{{ menu.data | translate }}</div>
                    </div>
                </div>
                <div class="element" *ngIf="menu.type === HeaderMenuItemType.BenchMark">
                    <div class="content">
                        <div class="content-caption">{{ menu.caption | translate }}</div>
                        <div class="content-data"><app-timer [time]="+menu.data"></app-timer></div>
                    </div>
                </div>      
            </div>
        </div>  
    </div>
    <div class="video-mode" *ngIf="displayMode === DisplayMode.Video">
        <div class="left" *ngFor = "let menu of leftMenuItems">
            <div class="title" *ngIf="menu.type === HeaderMenuItemType.Title">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                </div>
            </div>
            <div class="timer" *ngIf="menu.type === HeaderMenuItemType.Timer">
                <div class="content">
                    <div class="content-caption">{{ videoCaption }}</div>
                </div>
            </div> 
        </div>
        <div class="right" *ngFor = "let menu of rightMenuItems">
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.Element">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div>
            <div class="sub-element" *ngIf="menu.type === HeaderMenuItemType.SubElement">
                <div class="content">
                    <div class="content-caption">{{ menu.caption | translate }}</div>
                    <div class="content-data">{{ menu.data | translate }}</div>
                </div>
            </div> 
            <div class="element" *ngIf="menu.type === HeaderMenuItemType.LogOut">
                <a class="logout" (click)="logOut()"></a>
            </div>  
            <div class="skip" *ngIf="menu.type === HeaderMenuItemType.Skip">
                <a (click)="clickDefaultButton(menu.id)">{{ menu.caption | translate }}</a>
            </div> 
            <div class="close" *ngIf="menu.type === HeaderMenuItemType.Close">
                <a (click)="navigate(menu.url)"></a>
            </div>       
        </div>
    </div>
    <div class="bottom" *ngIf="bottomMenuItems && bottomMenuItems.length > 0">
        <div class="list" *ngFor = "let menu of bottomMenuItems">
            <a href="{{ menu.url }}" target="_blank">{{ menu.caption | translate }}</a>
        </div>
    </div>
</header>
