import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FooterMenuItem, FooterService } from 'src/app/core/services/footer.service';
import { BottomHeaderMenuItem, DisplayMode, HeaderMenuItem, HeaderMenuItemType, HeaderService } from 'src/app/core/services/header.service';
import { LoggingService } from 'src/app/core/services/logging.service';
import { OrderLine, OrderLineStatus } from 'src/app/shared-kernel/models';

@Component({
  selector: 'app-put-away',
  templateUrl: './put-away.component.html',
  styleUrls: ['./put-away.component.scss']
})
export class PutAwayComponent implements OnInit {

  _orderLineItem: OrderLine;
  get orderLineItem(): OrderLine {
      if (!this._orderLineItem) {
        throw new Error('Attribute "OrderLine" is required');
      }
      return this._orderLineItem;
  }
  @Input() set orderLineItem(value: OrderLine) {
      this._orderLineItem = value;
  }

  @Output() putAwayClicked = new EventEmitter<OrderLine>();

  constructor(private headerService: HeaderService,
              private router: Router,
              private loggingService: LoggingService,
              private location: LocationStrategy,
              private footerService: FooterService) {
                history.pushState(null, null, window.location.href);
                this.location.onPopState(() => {history.pushState(null, null, window.location.href);
              });
            }

  ngOnInit(): void {
    this.loggingService.log('onInit');
  }

  putAway() {
    this._orderLineItem.status = OrderLineStatus.PutAway;
    this.putAwayClicked.emit(this.orderLineItem);
  }
}
