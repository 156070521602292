import { OrderLine } from './order-line.viewmodel';
import { Dimensions, Weight } from './packing-shipment-item.model';

export class BaseItem {
    public toteId: string;
    public productId?: string;
    public quantity?: number;
    public requiredQuantity?: number;
    public serialNumber?: string;
    public lotNumber?: string;
    public expirationDateTime?: string;
    public weight?: Weight;
    public dimensions?: Dimensions;
    public licensePlate?: string;
}

export class Item extends BaseItem {
    public id?: string;
    public productName?: string;
    public productNumber?: string;
    public description?: string;
    public orderLineItemId?: string;
    public price?: Price;
    public countryOfOrigin?: string;
    public containerLpn?: string;
    public customsHarmonizedSystemCode?: string;
    public customsOriginCountryCode?: string;
    public customsEccn?: string;
    public hazmatUnNumber?: string;
    public lotNumbers?: string[];
    public serialNumbers?: string[];
  }

export class Price {
    constructor(
        public value: number,
        public currency: string
    ) {

    }
}
