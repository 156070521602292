import { Injectable, OnInit } from '@angular/core';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import { CookieService } from 'ngx-cookie-service';
import Queue from 'queue-promise';
import { Observable, Subject, of, from } from 'rxjs';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root'})

export class LabelPrintingService {

    private zPrint: any;
    public availablePrinters: LabelPrinter[] = [
        {
            uid: 'none',
            printerObj: {
                name: 'None'
            }
        }
    ];
    public savedShippingLabelPrinter: LabelPrinter;
    public savedLpnLabelPrinter: LabelPrinter;
    public printQueueObs = new Subject<any>();

    queue = new Queue({
        concurrent: 1,
        start: true,
        interval: 1
    });

    constructor(
        private cookieService: CookieService,
        private loggingService: LoggingService,
        ) {
        this.zPrint = new ZebraBrowserPrintWrapper();
        this.setAvailablePrinters();
        this.setSavedPrinters();
    }

    getSavedPrinterByType(printerType: LabelPrinterType): LabelPrinter
    {
        try {
            let printer;
            if ( printerType === 0 ) {
                printer = this.savedShippingLabelPrinter;
            }
            if (printerType === 1) {
                printer = this.savedLpnLabelPrinter;
            }
            return printer;
        } catch (error) {
            throw new Error(error);
        }
    }

    savePrinter(printerUid: string, printerType: LabelPrinterType)
    {
        if (this.availablePrinters.length > 0 && printerUid !== 'none') {
            this.loggingService.log(printerUid);
            const savePrinterObj = this.availablePrinters.find( x => x.printerObj.uid === printerUid);
            const savePrinter = {
                uid: savePrinterObj.uid,
                printerObj: savePrinterObj.printerObj
            };

            if (printerType === LabelPrinterType.ShippingLabel) {
                this.cookieService.set('shipping-label-printer', JSON.stringify(savePrinter), 9999);
                this.savedShippingLabelPrinter = savePrinter;
            }

            if (printerType === LabelPrinterType.LPN) {
                this.cookieService.set('lpn-label-printer', JSON.stringify(savePrinter), 9999);
                this.savedLpnLabelPrinter = savePrinter;
            }

        } else {
            if (printerType === LabelPrinterType.ShippingLabel) {
                this.cookieService.delete('shipping-label-printer');
                this.savedShippingLabelPrinter = null;
            }

            if (printerType === LabelPrinterType.LPN) {
                this.cookieService.delete('lpn-label-printer');
                this.savedLpnLabelPrinter = null;
            }
        }
    }

    setSavedPrinters(){
        try {
            const savedShippingPrinterCookie = this.cookieService.get('shipping-label-printer');
            const savedLpnPrinterCookie =  this.cookieService.get('lpn-label-printer');

            if (savedShippingPrinterCookie) {
                this.savedShippingLabelPrinter = JSON.parse(savedShippingPrinterCookie);
            }

            if (savedLpnPrinterCookie) {
                this.savedLpnLabelPrinter = JSON.parse(savedLpnPrinterCookie);
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    async setAvailablePrinters(){
        try {
            const printers = await this.zPrint.getAvailablePrinters();
            printers.forEach(printerObj => {
                this.loggingService.log(printerObj);
                this.availablePrinters.push(
                    {
                        uid: printerObj.uid,
                        printerObj
                    }
                );
            });
        } catch (error) {
            this.setPrintQueueResponse({
                success: false,
                reference: 'browserPrintInstalled',
                message: 'Unable to find any available label printers. Is BrowserPrint installed and configured?',
                response: ''
            });
        }
    }

    async printLabel(reference: string, zplString: string, printerType: LabelPrinterType) {
        try {
            const printer = this.getSavedPrinterByType(printerType);
            if (printer) {
                this.loggingService.log(printer);
                this.zPrint.setPrinter(printer.printerObj);

                const printerStatus = await this.zPrint.checkPrinterStatus();
                if (printerStatus.isReadyToPrint) {
                    const formatStart = '^XA^LL200^FO80,50^A0N36,36^FD';
                    const formatEnd = '^FS^XZ';
                    let toPrint = zplString;

                    if (printerType === LabelPrinterType.ShippingLabel) {
                        toPrint = formatStart + zplString + formatEnd;
                    }

                    this.zPrint.print(toPrint);
                    this.setPrintQueueResponse({
                        success: true,
                        reference,
                        message: 'Print Complete',
                        response: printerStatus.isReadyToPrint
                    });

                    return true;
                } else {
                    this.setPrintQueueResponse({
                        success: false,
                        reference,
                        message: 'An error occurred while priting. Please try again.',
                        response: printerStatus.isReadyToPrint
                    });

                    return false;
                }
            } else {
                this.setPrintQueueResponse({
                    success: false,
                    reference,
                    message: 'A label printer was not selected',
                    response: 'No printer was selected'
                });
            }
        } catch (error) {
            throw new Error(error);
        }
    }

    addLabelPrintQueue(reference = null, zplString, printerType) {
        this.queue.enqueue(() => this.printLabel(reference, zplString, printerType));
    }

    setPrintQueueResponse(statusMsg: any)
    {
        this.printQueueObs.next(statusMsg);
    }

    onSetPrintQueueItem(): Observable<any>
    {
      return this.printQueueObs.asObservable();
    }
}

export enum LabelPrinterType {
    ShippingLabel = 0,
    LPN = 1
}

export class LabelPrinter {
    constructor(
      public uid: string,
      public printerObj: any
    ) {
    }
}
