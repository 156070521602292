import { json } from '@angular-devkit/core';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class BenchMarkService {

    constructor(private cookieService: CookieService) {}

    public getBenchMark(): BenchMarkItem[] {
        const cookies = this.cookieService.get('benchmark');
        if (!cookies) {
            return null;
        }

        const benchmark = JSON.parse(cookies);
        if (!benchmark) {
            return null;
        }

        return benchmark;
    }

    public getBenchMarkByToteId(toteId: string): BenchMarkItem {
        const newBenchMark: BenchMarkItem = {
            boxes: 0,
            seconds: 0,
            toteId
        };

        const cookies = this.cookieService.get('benchmark');
        if (!cookies) {
            return newBenchMark;
        }

        const benchmark = JSON.parse(cookies);
        if (!benchmark) {
            return newBenchMark;
        }

        const index = benchmark?.findIndex(x => x.toteId === toteId);
        if (index !== -1) {
            return benchmark[index];
        }

        return newBenchMark;
    }

    public setBenchMarkByToteId(toteId: string, benchmark: BenchMarkItem) {
        const cookies = this.cookieService.get('benchmark');
        if (!cookies) {
            this.cookieService.set('benchmark', JSON.stringify([benchmark]), 9999);
            return;
        }

        const tempBenchmark: BenchMarkItem[] = JSON.parse(cookies);
        if (!tempBenchmark) {
            this.cookieService.set('benchmark', JSON.stringify([benchmark]), 9999);
            return;
        }

        const index = tempBenchmark.findIndex(x => x.toteId === toteId);
        if (index !== -1) {
            tempBenchmark[index].seconds = benchmark.seconds;
            tempBenchmark[index].boxes = benchmark.boxes;
        } else {
            tempBenchmark.push(benchmark);
        }
        this.cookieService.set('benchmark', JSON.stringify(tempBenchmark), 9999);
    }

    public clearBenchMark() {
        this.cookieService.delete('benchmark');
    }
}

export class BenchMarkItem {
    toteId: string;
    seconds: number;
    boxes: number;
}
