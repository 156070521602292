import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';


export interface IProductApiService {
    getProduct(productId: string, accountId: string);
    getProductSynchronousCall(productId: string, accountId: string);
}

export const INSTRUCTION_API_SERVICE = new InjectionToken<IProductApiService>('IProductApiService');
