import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent, HeaderComponent, LoginFormComponent } from './components';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { MainContentComponent } from './layouts';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuardService, AuthService } from './services';

import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderService } from './services/header.service';
import { LoginContentComponent } from './layouts/login-content/login-content.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedKernelModule } from '../shared-kernel/shared-kernel.module';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        LayoutModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        SharedKernelModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatTableModule,
        MatFormFieldModule
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        LoginFormComponent,
        MainContentComponent,
        LoginContentComponent
    ],
    exports: [
        CommonModule,
        FooterComponent,
        HeaderComponent,
        LoginFormComponent,
        MainContentComponent,
        LoginContentComponent,
        HttpClientModule,
        RouterModule,
        LayoutModule
    ],
    providers: [AuthService, HeaderService, HeaderService, AuthGuardService]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
