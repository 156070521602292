<div class="main-content" [ngClass]="{
    'small': leftMenuItems && leftMenuItems.length !== 0
        && !(topMenuItem || notification),
    'medium': leftMenuItems && leftMenuItems.length !== 0
        && (topMenuItem || notification)
    }">
    <app-header></app-header>

    <div class="content">
        <div class="header-top-space" *ngIf="topMenuItem || notification"></div>
        <div class="header-space" *ngIf="leftMenuItems && leftMenuItems.length !== 0"></div>
        <div class="header-bottom-space" *ngIf="bottomMenuItems && bottomMenuItems.length !== 0"></div>
        <ng-content></ng-content>
    </div>

    <app-footer></app-footer>
</div>
