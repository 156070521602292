import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { IPackingInstructionsApiService } from '../packing-instructions.api.interface';


@Injectable()
export class PackingInstructionsApiServiceStub implements IPackingInstructionsApiService {
    constructor() {

    }

    getBox() {
        throw new Error('Method not implemented.');
    }
}
