import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { IProblemOrderApiService } from '../problem-order.api.interface';


@Injectable()
export class ProblemOrderApiServiceStub implements IProblemOrderApiService {
    constructor() {

    }

    getOrder() {
        throw new Error('Method not implemented.');
    }
}
