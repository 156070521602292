<footer *ngIf="(rightMenuItems && rightMenuItems?.length !== 0) || (middleMenuItems && middleMenuItems?.length !== 0)">

    <div class="left">
        <span class="title" *ngIf="title && title.length > 0">{{ title | translate }}</span>
        <span *ngFor = "let menu of leftMenuItems">
            <button (click)="clickDefaultButton(menu.id)" [ngClass]="{
                'replay': menu.type === FooterMenuItemType.ReplayButton,
                'pause': menu.type === FooterMenuItemType.PauseButton}">{{ menu.caption | translate }}</button>
        </span>
    </div>
    <div class="middle" *ngIf="displayMode === DisplayMode.Default">
        <span *ngFor = "let menu of middleMenuItems">
            <button (click)="clickDefaultButton(menu.id)"  *ngIf="menu.type !== FooterMenuItemType.DefaultButtonWithPackingSlip" [ngClass]="{
                'white-button': menu.type === FooterMenuItemType.DefaultWhiteButton,
                'button': menu.type === FooterMenuItemType.DefaultButton}">{{ menu.caption | translate }}</button>
            <button (click)="clickDefaultButton(menu.id)" *ngIf="menu.type === FooterMenuItemType.DefaultButtonWithPackingSlip"
                class="button">{{ menu.caption | translate }}</button>
        </span>    
    </div>
    <div class="right">
        <span *ngFor = "let menu of rightMenuItems">
            <button (click)="clickDefaultButton(menu.id)" *ngIf="menu.type !== FooterMenuItemType.DefaultButtonWithPackingSlip" [ngClass]="{
                'white-button': menu.type === FooterMenuItemType.DefaultWhiteButton,
                'back': menu.type === FooterMenuItemType.BackButton,
                'next': menu.type === FooterMenuItemType.NextButton}">{{ menu.caption | translate }}</button>
            <button (click)="clickDefaultButton(menu.id)" *ngIf="menu.type === FooterMenuItemType.DefaultButtonWithPackingSlip">{{ menu.caption | translate }}</button>           
        </span>    
    </div>
    <div class="play-list" *ngIf="displayMode === DisplayMode.Video">
        <div class="items">
            <div class="item" [ngClass]="{
                    'watched': menu.linkStatus === LinkStatus.Watched,
                    'selected': menu.linkStatus === LinkStatus.Selected,
                    'text-instructions': !menu.videoUrl
                }" *ngFor = "let menu of middleMenuItems; let i = index">
                <div class="top"></div>
                <div class="middle"></div>
                <div class="bottom">{{ i + 1 }}</div>
                <div class="progress-bar" [ngStyle]="{'width': progress + '%'}" *ngIf="menu.linkStatus === LinkStatus.Selected && menu.videoUrl">
                    <div class="top"></div>
                    <div class="middle"></div>
                    <div class="bottom"></div>         
                </div>
            </div>
        </div>
    </div>
</footer>