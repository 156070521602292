import { HttpClient } from '@angular/common/http';
import { Account } from '../../models';
import { BaseApiService } from '../base.api.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { IAccountApiService } from './account.api.interface';
import { Observable, of, iif } from 'rxjs';
import { map, shareReplay, switchMap, publishReplay, refCount } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

@Injectable({ providedIn: 'root'})
export class AccountApiService extends BaseApiService implements IAccountApiService {
    private BASE_URL = `${environment.packApiUrl}/accounts`;
    private cache$: Observable<Account>;

    constructor(public http: HttpClient,
                public msalService: MsalService) {
                super(http, msalService);
    }

    getAccounts(name: string) {
        return this.get<Account[]>(`${environment.packApiUrl}/inquiries/accounts/name?searchText=${name}`);
    }

    getCachedAccount(accountId: string, doRefresh: boolean = false) {
        if (!this.cache$ || doRefresh) {
            this.cache$ = this.requestAccountById(accountId).pipe(
                shareReplay(1)
            );
        }
        return this.cache$;
    }

    requestAccountById(accoutnId: string) {
        return this.get<Account>(`${this.BASE_URL}/${accoutnId}`).pipe(shareReplay(1));
    }

    getAccount(accoutnId: string) {
        return this.get<Account>(`${this.BASE_URL}/${accoutnId}`).pipe(shareReplay(1));
    }

    getAccountSynchronousCall(accoutnId: string) {
        const request = new XMLHttpRequest();
        request.open('GET', `${this.BASE_URL}/${accoutnId}`, false);
        request.setRequestHeader('owd-api-key', environment.packApiKey);
        request.send();

        if (request.status === 200) {
            return request.response;
        } else {
            throw new Error('request failed');
        }
    }
}
