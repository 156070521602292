import { forEach } from '@angular-devkit/schematics';
import { ProductApiService } from 'src/app/shared-kernel/services/product/product.api.service';
import { DatePipe } from '@angular/common';
import { Contact } from './../../models/shipement-response';
import { ShipementResponse } from 'src/app/shared-kernel/models/shipement-response';
import { Order } from './../../../shared-kernel/models/order.model';
import { AfterViewInit, Component, Inject, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AccountApiService, OrderApiService } from '../../services';
import { Router } from '@angular/router';
import { SalesOrder, Contacts, CommInvoiceOrderLineItem } from './../../models/sales-order.model';
import { PackOrderStateResponse } from '../../models/pack-order-state-response';
import { Item } from '../../models/item.model';
import { Product } from '../../models/product.model';
import { runInThisContext } from 'vm';
import { Account } from '../../models';
import * as countryCodeLookup from 'country-code-lookup';

@Component({
  selector: 'app-generate-comm-invoice',
  templateUrl: './generate-comm-invoice.component.html',
  styleUrls: ['./generate-comm-invoice.component.scss', '../../../../assets/styles/comminvoice.css'],
  providers: [ProductApiService],
  encapsulation: ViewEncapsulation.None
})
export class GenerateCommInvoiceComponent implements OnInit {

  order: SalesOrder;
  shipment: ShipementResponse;
  shippedDate: string;

  _orderId: string;

  originAddress: Contacts;
  billingAddress: Contacts;
  shippingAddress: Contacts;
  shippedProducts: CommInvoiceOrderLineItem[] = [];
  totalWeight: number;

  get orderId(): string {
    return this._orderId;
  }

  @Input() set orderId(value: string) {
    this._orderId = value;
  }

  constructor(private orderService: OrderApiService,
              private productApiService: ProductApiService,
              private datePipe: DatePipe,
              private router: Router) {
  }


  ngOnInit(): void {
    try {
      this.totalWeight = 0;
      this.order = JSON.parse(this.orderService.getSalesOrderSynchronousCall(this.orderId));

      if (this.order.shipmentIds.length > 0) {
        this.shipment = JSON.parse(this.orderService.getShipmentByIdSynchronousCall(this.order.shipmentIds[0]));
        this.shippedDate = this.datePipe.transform( this.shipment.shipmentDateTime, 'yyyy/MM/dd');
        this.shipment.parcels?.forEach(item => {
          this.totalWeight += item.weight?.value;
        });
      }

      // Set billing and shipping addresses
      this.billingAddress = this.order.contacts.find((contact) => contact.contactType === 'billing');
      this.shippingAddress = this.shipment.destination;

      // Get order line product details
      this.order.orderLines?.forEach(item => {
        const fullProd = JSON.parse(this.productApiService.getProductSynchronousCall(item.productId, this.order.accountId)) as Product;
        const product = {} as CommInvoiceOrderLineItem;
        product.productName = fullProd.name;
        product.productDetails = fullProd.description;
        product.productDiscount = item.discountValue.toFixed(2);
        product.productOrigin = countryCodeLookup.byIso(fullProd.customsOriginCountryCode ?? 'US').country;
        product.productPrice = (item.priceValue - item.discountValue).toFixed(2);
        product.productUnitPrice = item.priceValue.toFixed(2);
        product.productQuantity = item.quantity;
        this.shippedProducts.push(product);
      });

    } catch (e) {
       this.router.navigateByUrl('/404');
    }
  }

  getSubtotal(): number {
    let total = 0;
    this.order?.orderLines.forEach(x => {
      total += (x.quantity * x.priceValue) - x.discountValue;
    });
    return total;
  }

  getTotal(): number {
    let total = 0;
    this.order?.orderLines.forEach(x => {
      total += (x.quantity * x.priceValue) - x.discountValue;
    });
    return total;
  }

  getTotalDiscount(): number {
    let total = 0;
    this.order?.orderLines.forEach(x => {
      total += x.discountValue;
    });
    return total;
  }
}
