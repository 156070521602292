import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Locale } from 'src/app/shared-kernel/models/locale.model';

@Injectable({ providedIn: 'root'})
export class HeaderService {

    constructor() {}

    protected setHeaderSubject = new Subject<{ title: string, mode: DisplayMode, leftMenuItems: HeaderMenuItem[],
      rightMenuItems: HeaderMenuItem[], bottomMenuItems: BottomHeaderMenuItem[], topHeader: TopHeaderMenuItem,
      middleMenuItems?: HeaderMenuItem[]}>();

    protected setNotificationSubject = new Subject<TopHeaderMenuItem>();

    protected setFilterSubject = new Subject<string>();

    protected clickDefaultButtonSubject = new Subject<{ id: string, data?: string, caption?: string }>();

    protected setLocaleSubject = new Subject<Locale>();

    protected setStateSubject = new Subject<State>();

    public setHeader(obj: { title: string, mode: DisplayMode, leftMenuItems: HeaderMenuItem[],
      rightMenuItems: HeaderMenuItem[], bottomMenuItems: BottomHeaderMenuItem[], topHeader: TopHeaderMenuItem,
      middleMenuItems?: HeaderMenuItem[]}) {
      this.setHeaderSubject.next(obj);
    }

    public getNotification() {
      const notification = JSON.parse(sessionStorage.getItem('notification'));
      if (notification) {
        sessionStorage.removeItem('notification');
        this.setNotificationSubject.next(notification);
      }
    }

    public setNotification(notificationHeader: TopHeaderMenuItem) {
      sessionStorage.setItem('notification', JSON.stringify(notificationHeader));
    }

    public hideNotification() {
      this.setNotificationSubject.next(null);
    }

    public setFilter(filter: string) {
      this.setFilterSubject.next(filter);
    }

    public clickDefaultButton(object: { id: string, data?: string, caption?: string }) {
      this.clickDefaultButtonSubject.next(object);
    }

    public setLocale(locale: Locale) {
      this.setLocaleSubject.next(locale);
    }

    public setState(state: State) {
      this.setStateSubject.next(state);
    }

    public onSetHeader(): Observable<{ title: string, mode: DisplayMode, leftMenuItems: HeaderMenuItem[],
      rightMenuItems: HeaderMenuItem[], bottomMenuItems: BottomHeaderMenuItem[], topHeader: TopHeaderMenuItem,
      middleMenuItems?: HeaderMenuItem[]}> {
      return this.setHeaderSubject.asObservable();
    }

    public onSetNotification(): Observable<TopHeaderMenuItem> {
      return this.setNotificationSubject.asObservable();
    }

    public onSetFilter(): Observable<string> {
      return this.setFilterSubject.asObservable();
    }

    public onClickDefaultButton(): Observable<{id: string, data?: string, caption?: string }> {
      return this.clickDefaultButtonSubject.asObservable();
    }

    public onSetLocale(): Observable<Locale> {
      return this.setLocaleSubject.asObservable();
    }

    public onSetState(): Observable<State> {
      return this.setStateSubject.asObservable();
    }
}

export class TopHeaderMenuItem {
  id: string;
  caption?: string;
  data?: string;
  align?: MenuTextAlign;
  color?: MenuColor;
  showCloseButton?: boolean;
}

export class HeaderMenuItem {
    id: string;
    type?: HeaderMenuItemType;
    caption?: string;
    data?: any;
    url?: string;
}

export class BottomHeaderMenuItem {
  id: string;
  caption?: string;
  url?: string;
}

export enum HeaderMenuItemType {
    Logo = 1,
    Element = 2,
    SubElement = 3,
    LogOut = 4,
    Back = 5,
    Close = 6,
    Skip = 7,
    Title = 8,
    Timer = 9,
    BenchMark = 10,
    ProblemOrder = 11,
    UserName = 12,
    ReportProblemOrder = 13,
    Locale = 14,
    State = 15,
    Search = 16,
    SearchAccount = 17,
    ProblemOrderDetails = 18,
    TimeClock = 19,
    Boxes = 20,
    Orders = 21,
    CommercialInvoice = 22
}

export enum DisplayMode {
  Default = 1,
  Title = 2,
  Search = 3,
  DefaultWhite = 4,
  Video = 5,
  None = 6
}

export enum MenuTextAlign {
  Center = 1,
  Left = 2
}

export enum MenuColor {
  Green = 1,
  Red = 2
}

export enum State {
  Active = 'active',
  Draft = 'draft'
}
