import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { async } from '@angular/core/testing';
import { Injectable, OnInit } from '@angular/core';
import { ReplaySubject, defer } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, of } from 'rxjs';
import { ConditionalExpr, ThrowStmt } from '@angular/compiler';
import { timer, asyncScheduler } from 'rxjs';
import { concatAll, map, finalize, takeUntil, scan, mergeAll, concatMap, concat } from 'rxjs/operators';
import { HeaderService } from 'src/app/core/services/header.service';
import { LabelPrinter } from 'src/app/shared-kernel/models/label-printer.model';
import { Buffer } from 'buffer';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root' })

export class HardwareService {
  public filters = [
    {
      vendorId: 0x0EB8,
      productId: 0xF000
    },
    {
      vendorId: 0x0EB8,
      productId: 0xF000
    }
  ];
  public scaleData = new ReplaySubject<any>();

  constructor(private loggingService: LoggingService) {}

  public async getScale() {
    if ('hid' in navigator) {
      try {
        const filters = [
          {
            vendorId: 0x0EB8,
            productId: 0xF000
          },
          {
            vendorId: 0x0EB8,
            productId: 0xF000
          }
        ];
        const device = await (navigator as any).hid.requestDevice({ filters });
        return device;
      } catch (error) {
        this.loggingService.log(error);
      }
    }
  }

  public async getDevices() {
    if ('hid' in navigator) {
      try {
        const filters = this.filters;
        const devices = await (navigator as any).hid.getDevices(filters);
        this.loggingService.log(devices);
        return devices;
      } catch (error) {
        this.loggingService.log(error);
      }
    }
  }

  public async getScaleData() {
    if ('hid' in navigator) {
      const filters = this.filters;
      const [device] = await (navigator as any).hid.getDevices(filters);
      if (!device) { return; }
      await device.open();
      device.addEventListener('inputreport', event => {
        // tslint:disable-next-line: no-shadowed-variable
        const { data, device, reportId } = event;
        if (device.productId !== 0xF000) { return; }
        const buf = Buffer.from(data.buffer);
        const oz = (buf.readUInt8(3) + (buf.readUInt8(4) * 256)) / 10;
        const value = oz / 16;
        const status = buf.readUInt8(0);

        let statusMessage = 'start';
        if (status === 2) {
          statusMessage = 'ready';
        } else if (status === 3) {
          statusMessage = 'weighing';
        } else if (status === 4) {
          statusMessage = 'measured';
        } else if (status === 5) {
          statusMessage = 'calibrate';
        }

        const scaleData = {
          status: statusMessage,
          weight: value
        };
        this.scaleData.next(scaleData);
      });
    }
  }
}
