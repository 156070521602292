import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { IOrderLineApiService } from '../order-line.api.interface';


@Injectable()
export class OrderLineApiServiceStub implements IOrderLineApiService {
    constructor() {

    }

    getBox() {
        throw new Error('Method not implemented.');
    }
}
