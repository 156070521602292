import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { async } from '@angular/core/testing';
import { Injectable, OnInit } from '@angular/core';
import { ReplaySubject, defer } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, of, from } from 'rxjs';
import { ConditionalExpr, ThrowStmt } from '@angular/compiler';
import { timer, asyncScheduler } from 'rxjs';
import { concatAll, map, finalize, takeUntil, scan, mergeAll, concatMap, concat } from 'rxjs/operators';
import { HeaderService } from 'src/app/core/services/header.service';
import { LabelPrinter } from 'src/app/shared-kernel/models/label-printer.model';
import { _MatTabLinkBase } from '@angular/material/tabs';
import { PriorityQueue } from '@angular-devkit/core';
import { queueScheduler } from 'rxjs';
import Queue from 'queue-promise';
import { LoggingService } from './logging.service';
declare const BrowserPrint: any;
let _defaultPrinter: any;
let _availablePrinters: any;
let _browserPrintInstalledFlag: boolean;

@Injectable({ providedIn: 'root'})

export class ZebraLabelPrintingService {

    private PackAppZebra: any; // A wrapper for BrowserPrint
    public savedPrinters: [] = [];
    public savedDefaultPrinter: any;
    public availableLabelPrinters: any;
    public browserPrintInstalled = true;
    public printedLabels: [];
    public BrowserPrint: any;
    public printQueueObs = new Subject<any>();
    public printQueue = [];

    labelPrintQueue = new Subject<() => Observable<any>>();
    labelPrintCount = 1;
    queueRunning = false;
    queue = new Queue({
        concurrent: 1,
        start: true,
        interval: 1
    });

    constructor(
        private cookieService: CookieService,
        private loggingService: LoggingService
        ) {
        this.BrowserPrint = BrowserPrint;
        // Check if browser print installed, must run once in constructor and then on init
        this._getDefaultPrinter();
        this._getAvailablePrinters();
    }

    private _getDefaultPrinter = () => {
        // tslint:disable-next-line: only-arrow-functions
        this.BrowserPrint.getDefaultDevice('printer', function(printer) {
                _defaultPrinter = printer;
                _browserPrintInstalledFlag = true;
            // tslint:disable-next-line: only-arrow-functions
            }, function() {
                _browserPrintInstalledFlag = false;
                _defaultPrinter = [];
        });
    }

    private _getAvailablePrinters = () => {
        this.BrowserPrint.getLocalDevices(
            // tslint:disable-next-line: only-arrow-functions
            function(printers) {
                // tslint:disable-next-line: triple-equals
                if (printers != undefined) {
                    _availablePrinters = printers.printer;
                }
            },
            // tslint:disable-next-line: only-arrow-functions
            function(error){
                _availablePrinters = [];
            }
        );
        this.availableLabelPrinters = _availablePrinters;
    }

    public _sendData = (reference, data) => {
        // change with selected printer
        // tslint:disable-next-line: only-arrow-functions
        return new Promise(function(resolve, reject) {
            if (_browserPrintInstalledFlag || _defaultPrinter !== undefined) {
                _defaultPrinter.sendThenRead('~HQES',
                    // tslint:disable-next-line: only-arrow-functions
                    function(text) {
                        const statuses = new Array();
                        let ok = false;
                        // tslint:disable-next-line: variable-name
                        const is_error = text.charAt(70);
                        const media = text.charAt(88);
                        const head = text.charAt(87);
                        const pause = text.charAt(84);
                        // check each flag that prevents printing
                        // tslint:disable-next-line: triple-equals
                        if (is_error == '0') {
                            ok = true;
                            statuses.push('Ready to Print');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (media == '1') {
                            statuses.push('Paper out');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (media == '2') {
                            statuses.push('Ribbon Out');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (media == '4') {
                            statuses.push('Media Door Open');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (media == '8') {
                            statuses.push('Cutter Fault');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (head == '1') {
                            statuses.push('Printhead Overheating');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (head == '2') {
                            statuses.push('Motor Overheating');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (head == '4') {
                            statuses.push('Printhead Fault');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (head == '8') {
                            statuses.push('Incorrect Printhead');
                        }
                        // tslint:disable-next-line: triple-equals
                        if (pause == '1') {
                            statuses.push('Printer Paused');
                        }
                        // tslint:disable-next-line: triple-equals
                        if ((!ok) && (statuses.length == 0)) {
                            statuses.push('Error: Unknown Error');
                        }

                        const formatStart = '^XA^LL200^FO80,50^A0N36,36^FD';
                        const formatEnd = '^FS^XZ';
                        // tslint:disable-next-line: triple-equals
                        if (statuses.join() == 'Ready to Print') {
                            _defaultPrinter.send(
                                formatStart + data + formatEnd,
                                resolve(
                                    {
                                        success: true,
                                        reference,
                                        message: 'Print Complete',
                                        response: text
                                    }
                                ),
                                reject(
                                    {
                                        success: false,
                                        reference,
                                        message: 'An error occurred while priting. Please try again.',
                                        response: text
                                    }
                                )
                            );
                        } else {
                            reject(
                                {
                                    success: false,
                                    reference,
                                    message: 'An error occurred while priting. Please try again.',
                                    response: statuses.join()
                                }
                            );
                        }

                // tslint:disable-next-line: only-arrow-functions
                }, function(error) {
                    reject({
                        success: false,
                        reference, message: 'An error occurred while priting. Please try again.' , response: error}); } );
            } else {
                reject(
                    {
                        success: false,
                        message: 'An error occurred while priting. Please try again.',
                        response: 'BrowserPrint is not installed'
                    }
                );
            }
        });
    }

    public init() {
        this._getDefaultPrinter();
        this._getAvailablePrinters();
        if (_browserPrintInstalledFlag) {
            this.setDefaultLabelPrinter();
        }
    }

    setSavedLabelPrinters(savedLabelPrinters) {
        if (savedLabelPrinters.length > 0) {
            this.cookieService.set('saved-label-printers', JSON.stringify(savedLabelPrinters), 9999);
        }
    }

    setSavedDefaultLabelPrinter(defaultLabelPrinter) {
        this.cookieService.set('default-label-printer', JSON.stringify(defaultLabelPrinter), 9999);
    }

    getSavedLabelPrinters() {
        const savedLabelPrintersJSON = this.cookieService.get('saved-label-printers');
        let savedLabelPrinters = [];
        if ( savedLabelPrintersJSON ) {
            savedLabelPrinters = JSON.parse(savedLabelPrintersJSON);
        } else {
            savedLabelPrinters = [];
        }
        return savedLabelPrinters;
    }

    getSavedDefaultLabelPrinter(){
        const savedDefaultLabelPrinterJSON = this.cookieService.get('default-label-printer');
        let savedDefaultLabelPrinter: any;
        if ( savedDefaultLabelPrinterJSON ) {
            savedDefaultLabelPrinter = JSON.parse(savedDefaultLabelPrinterJSON);
        } else {
            savedDefaultLabelPrinter = '';
        }
        return savedDefaultLabelPrinter;
    }

    setDefaultLabelPrinter() {
        const savedDefaultLabelPrinter = this.getSavedDefaultLabelPrinter();
        _defaultPrinter = _availablePrinters.find(e => e.uid === savedDefaultLabelPrinter.uid);
    }

    async sendData(reference, data) {
        const promise = this._sendData(reference, data);
        return await promise.then(
            sucess => {
                this.setPrintQueueItems(sucess);
            },
            failure => {
                this.setPrintQueueItems(failure);
            }
        ).catch(e => {
            this.loggingService.log(e);
        });
    }

    addLabelPrintQueue(reference = null, data) {
        this.queue.enqueue(() => this.sendData(reference, data));
    }

    setPrintQueueItems(statusMsg: any)
    {
        this.loggingService.log(statusMsg);
        this.printQueueObs.next(statusMsg);
    }

    onSetPrintQueueItem(): Observable<any>
    {
      return this.printQueueObs.asObservable();
    }

}
