import { DatePipe } from '@angular/common';
import { ShipementResponse } from 'src/app/shared-kernel/models/shipement-response';
import { SalesOrder, Contacts } from './../../models/sales-order.model';
import { Order } from './../../../shared-kernel/models/order.model';
import { AfterViewInit, Component, Inject, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AccountApiService, OrderApiService, ProductApiService } from '../../services';
import { Router } from '@angular/router';
import { Item } from '../../models/item.model';
import { Product } from '../../models/product.model';
import { runInThisContext } from 'vm';
import { Account } from '../../models';
import { LoggingService } from 'src/app/core/services/logging.service';

@Component({
  selector: 'app-generate-pack-slip',
  templateUrl: './generate-pack-slip.component.html',
  styleUrls: ['./generate-pack-slip.component.scss', '../../../../assets/styles/packslip.css'],
  providers: [ProductApiService],
  encapsulation: ViewEncapsulation.None
})
export class GeneratePackSlipComponent implements OnInit {

  order: SalesOrder;
  shipments: ShipementResponse[];
  account: Account;
  billingAddress: Contacts;
  shippingAddress: Contacts;
  tempJsonResponse = null;
  items: Item[];
  html = '';
  currentDate = new Date();

  _orderId: string;

  get orderId(): string {
    return this._orderId;
  }

  @Input() set orderId(value: string) {
    this._orderId = value;
  }

  constructor(private orderService: OrderApiService,
              private accountService: AccountApiService,
              private ProductService: ProductApiService,
              private datePipe: DatePipe,
              private loggingService: LoggingService,
              private router: Router) {
  }


  ngOnInit(): void {
    try {
      this.order = JSON.parse(this.orderService.getSalesOrderSynchronousCall(this.orderId));
      this.account = JSON.parse(this.accountService.getAccountSynchronousCall(this.order.accountId));
      this.billingAddress = this.order.contacts.find((contact) => contact.contactType === 'billing');
      this.shippingAddress = this.order.contacts.find((contact) => contact.contactType === 'delivery');
    } catch (e) {
      this.router.navigateByUrl('/404');
    }



    this.html = `
<div class="slip-customer-ref">
  <span>Ship Date: ${ this.datePipe.transform( this.currentDate, 'MM/dd/yyyy') }</span>
  <span>ORDER: ${ this.order.customerReferenceNumber ?? '' } </span>
</div>
<div class="slip-info">`;

    this.html += `
  <table>
    <tr>
      <td class="header width10">
        SOLD TO
      </td>
      <td class="width35">
      </td>
      <td class="header width10">
        SHIP TO
      </td>
      <td class="width55">
      </td>
    </tr>
    <tr>
      <td class="width10">
        Name:
      </td>
      <td class="width35">
        ${ (this.billingAddress?.firstName ?? '') + ' ' + (this.billingAddress?.lastName ?? '') }
      </td>
      <td class="width10">
        Name:
      </td>
      <td class="width55">
        ${ (this.shippingAddress?.firstName ?? '') + ' ' + (this.shippingAddress?.lastName ?? '') }
      </td>
    </tr>
    <tr>
      <td class="width10">
        Phone:
      </td>
      <td class="width35">
        ${ this.billingAddress?.phoneNumber ?? '' }
      </td>
      <td class="width10">
        Phone:
      </td>
      <td class="width55">
      ${ this.shippingAddress?.phoneNumber ?? '' }
      </td>
    </tr>
    <tr>
      <td class="width10">
        Email:
      </td>
      <td class="width35">
        ${ this.billingAddress?.emailAddress ?? '' }
      </td>
      <td class="width10">
        Email:
      </td>
      <td class="width55">
      ${ this.shippingAddress?.emailAddress ?? '' }
      </td>
    </tr>
    <tr>
      <td>
        Address:
      </td>
      <td>
        ${ (this.billingAddress?.addressLines[0] ?? '')
           + '<br>' + (this.billingAddress?.addressCity ?? '')
           + '<br>' +  (this.billingAddress?.addressStateOrProvinceCode ?? '')
           + '<br>' + (this.billingAddress?.addressPostalCode ?? '') }
      </td>
      <td>
        Address:
      </td>
      <td>
        ${ (this.shippingAddress?.addressLines[0] ?? '')
        + '<br>' + (this.shippingAddress?.addressCity ?? '')
        + '<br>' +  (this.shippingAddress?.addressStateOrProvinceCode ?? '')
        + '<br>' + (this.shippingAddress?.addressPostalCode ?? '') }
      </td>
      <td>
    </tr>
  </table>
</div>`;

    this.html += `
<div class="slip-details">
  <table>
    <tr>
      <td class="header number">
        SKU
      </td>
      <td class="header details">
        DETAILS
      </td>
      <td class="header price">
        QTY
      </td>
      <td class="header price">
        DISCOUNT
      </td>
      <td class="header price">
        PRICE
      </td>
      <td class="header price">
        TOTAL
      </td>
    </tr>`;

    this.order.orderLines?.forEach(item => {
      const product = JSON.parse(this.ProductService.getProductSynchronousCall(item.productId, this.order.accountId));
      this.html += `
    <tr class="item">
      <td class="text">
        ${ product.number ?? '' }
      </td>
      <td class="text">
        ${ product.name ?? '' }
      </td>
      <td class="price">
        ${ item.quantity ?? '' }
      </td>
      <td class="price">
        ${ '$' + item.discountValue.toFixed(2) ?? '' }
      </td>
      <td class="price">
        ${ '$' + item.priceValue.toFixed(2) ?? '' }
      </td>
      <td class="price">
        ${ '$' + ((item.priceValue ?? 1 * item.quantity ?? 1) - item.discountValue ?? 0).toFixed(2) }
      </td>
    </tr>`;
    });

    this.html += `
    </table>
  </div>`;

    this.html += `
    <div class="footer">`;

    this.html += `
    <div class="notes-form">
      <div class="caption">`;

    if (this.account.giftCardType === 'packing_slip' && this.order.giftMessage) {
      this.html += `
          <div class="gift-message-bottom">
          ${ this.order.giftMessage }
          </div>
        `;
    }

    this.html += `
      </div>
    </div>`;

    this.html += `
    <div class="price-form">
      <table>
        <tr>
          <td class="price-form-header">
            SUBTOTAL:
          </td>
          <td class="price-form-content">
            $${ this.getSubtotal().toFixed(2) }
          </td>
        </tr>
        <tr">
          <td class="price-form-header">
            DISCOUNT:
          </td>
          <td class="price-form-content">
           $${ this.getTotalDiscount().toFixed(2) }
          </td>
        </tr>
        <tr>
          <td class="price-form-header">
            TAX:
          </td>
          <td class="price-form-content">
           $${ this.order.orderTaxAmount.toFixed(2) }
          </td>
        </tr>
        <tr">
          <td class="price-form-header">
            SHIPPING:
          </td>
          <td class="price-form-content">
           $${ this.order.orderShipHandlingFee.toFixed(2) }
          </td>
        </tr>
      </table>
      <table class="total-form">
        <tr>
          <td class="price-form-header">
            TOTAL:
          </td>
          <td class="price-form-content total">
           $${ this.getTotal().toFixed(2) }
          </td>
        </tr>
      </table>
    </div>`;

    this.html += `</div>`;
  }

  getAccountName() {
    return this.account?.name ?? '';
  }

  getAccountLogo() {
    return this.account?.logoUrl ?? '';
  }

  getShipmentItem(item: Item): Item {
    if (this.tempJsonResponse?.shipment) {
      const items = this.tempJsonResponse?.shipment?.parcels
        .map(x => x.items).filter(x => x).reduce((a, b) => a.concat(b));
      return items.filter(x => x.productId.toLowerCase() === item.productId.toLowerCase())[0];
    }

    return null;
  }

  getSubtotal(): number {
    let total = 0;
    this.order?.orderLines.forEach(x => {
      total += (x.quantity * x.priceValue) - x.discountValue;
    });
    return total;
  }

  getTotal(): number {
    let total = 0;
    this.order?.orderLines.forEach(x => {
      total += (x.quantity * x.priceValue) - x.discountValue;
    });
    total += this.order.orderShipHandlingFee + this.order.orderTaxAmount;
    return total;
  }

  getTotalDiscount(): number {
    let total = 0;
    this.order?.orderLines.forEach(x => {
      total += x.discountValue;
    });
    return total;
  }

  formatPhoneNumber(phoneNumberString: string): string {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return '';
  }
}
