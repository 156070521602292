import { Issue } from './issue.model';

export class OrderLine {

    public issuesSet?: Issue[];
    public weight?: number;
    public weightOnScale?: number;
    public status?: OrderLineStatus;
    public details?: string[];
    public discount?: number;
    public currency?: string;
    public isScannable?: boolean;
    public isSerialized?: boolean;
    public isLotRequired?: boolean;
    public isEditable?: boolean;
    public barcode?: string;
    public requiredQuantity?: number;
    public lotNumbers?: string[];
    public serialNumbers?: string[];
    public lotNumber?: string;
    public serialNumber?: string;
    public packingMaterialId?: string;
    public packingMaterialName?: string;
    public photoUrl?: string;

    constructor(
        public id: string,
        public caption: string,
        public description: string,
        public quantity: number
    ) {

    }
  }

export enum OrderLineStatus {
    NotScanned = 0,
    Scanned = 1,
    PutAway = 2,
    Manual = 3,
    Weighet = 4
}

export enum WeightStatus {
    Default = 0,
    Calculating = 1,
    Success = 2,
    Error = 3
}
