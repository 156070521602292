export class Account {

    constructor(
      public parentId: string,
      public id: string,
      public code: string,
      public name: string,
      public logoUrl: string,
      public state: string,
      public weightTolerance: number,
      public giftCardType: string
    ) {

    }
  }
