import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, Subscription, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { MonitoringService } from 'src/app/shared-kernel/services/monitoring/monitoring.service';
import { LoggingService } from 'src/app/core/services/logging.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly loggingService: LoggingService,
    private readonly spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const spinnerSubscription: Subscription = this.spinnerService.spinner$.subscribe();
    return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        this.loggingService.log(error);
        this.loggingService.log(errorMessage);

        if (!environment.production) {
          this.snackBar.open(errorMessage, 'Error', {
            duration: 3000
          });
        }

        return throwError(error);
      }),
      finalize(() => spinnerSubscription.unsubscribe()));
  }
}
