<div class="dialog">
  <mat-dialog-content>
    <div class="content">
      <div class="title">LPN is missing on the box</div>
      <div class="description">To be able to add another box you must generate LPN and place it on the box.</div>
    </div>
  </mat-dialog-content>
  <div class="footer">
    <mat-dialog-actions>
      <button class="cancel" [mat-dialog-close]="false">Cancel</button>
      <button class="confirm" [mat-dialog-close]="true" cdkFocusInitial styleSheetFile="assets/styles/packslip.css">Generate</button>
    </mat-dialog-actions>
  </div>
</div>