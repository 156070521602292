import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { LoginFormComponent } from './core/components';
import { CoreModule } from './core/core.module';
import { AuthGuardService } from './core/services/auth.service';
import { MsalGuard } from '@azure/msal-angular';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { basename } from '@angular-devkit/core';
import { Observable } from 'rxjs';
import { Configuration } from 'msal';
import { PackSlipPreviewComponent } from './shared-kernel/components/pack-slip-preview/pack-slip-preview.component';
import { CommInvoicePreviewComponent } from './shared-kernel/components/comm-invoice-preview/comm-invoice-preview.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/scanning-order/scanning-order.module').then((m) => m.ScanningOrderModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'mvp',
    loadChildren: () => import('./modules/mvp/mvp.module').then((m) => m.MvpModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'problem-order',
    loadChildren: () => import('./modules/problem-order/problem-order.module').then((m) => m.ProblemOrderModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'scanning-box',
    loadChildren: () => import('./modules/scanning-box/scanning-box.module').then((m) => m.ScanningBoxModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'order-line',
    loadChildren: () => import('./modules/order-line/order-line.module').then((m) => m.OrderLineModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'packing-instructions',
    loadChildren: () => import('./modules/packing-instructions/packing-instructions.module').then((m) => m.PackingInstructionsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'required-supplies-line',
    loadChildren: () => import('./modules/required-supplies-line/required-supplies-line.module').then((m) => m.RequiredSuppliesLineModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'setup',
    loadChildren: () => import('./modules/setup/setup.module').then((m) => m.SetupModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'scale',
    loadChildren: () => import('./modules/scale/scale.module').then((m) => m.ScaleModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'packslip/:tote', component: PackSlipPreviewComponent,
  },
  {
    path: 'comminvoice/:tote', component: CommInvoicePreviewComponent,
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CoreModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
