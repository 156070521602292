import { Order } from './../../../shared-kernel/models/order.model';
import { AfterViewInit, Component, Inject, OnInit, Input } from '@angular/core';
import { Item, PackingMaterial, Product } from 'src/app/shared-kernel/models';
import { Parcel } from 'src/app/shared-kernel/models/packing-shipment-item.model';
import { OrderApiService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pack-slip-preview',
  templateUrl: './pack-slip-preview.component.html',
  styleUrls: ['./pack-slip-preview.component.scss']
})
export class PackSlipPreviewComponent implements OnInit, AfterViewInit {

  orderId: string;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.orderId = this.activatedRoute.snapshot.paramMap.get('tote');
  }

  ngAfterViewInit(): void {

  }
}
