import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { IScaleApiService } from '../scale.api.interface';


@Injectable()
export class ScaleApiServiceStub implements IScaleApiService {
    constructor() {

    }

    getBox() {
        throw new Error('Method not implemented.');
    }
}
