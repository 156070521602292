import { ToteHeaderLabel } from './../../models/tote.model';
import { SalesOrder } from './../../models/sales-order.model';
import { UpdateShipementRequest } from 'src/app/shared-kernel/models/update-shipement-request';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, ReplaySubject, of, iif } from 'rxjs';
import { map, shareReplay, switchMap, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IOrderApiService } from '..';
import { Order, Tote } from '../../models';
import { ShipementRequest } from '../../models/shipement-request';
import { ShipementResponse } from '../../models/shipement-response';
import { BaseApiService } from '../base.api.service';
import { AttachmentResponse } from '../../models/attachment-response';
import { AttachmentRequest } from '../../models/attachment-request';
import { MsalService } from '@azure/msal-angular';

const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 10000;

@Injectable({ providedIn: 'root'})
export class OrderApiService extends BaseApiService implements IOrderApiService {
    private BASE_URL_PACKING_ORDERS = `${environment.packApiUrl}/apps/pack/packing-orders`;
    private BASE_URL_ORDERS_V_1_1 = `${environment.packApiUrlv1_1}/orders`;
    private BASE_URL_SHIPMENTS_V_1_1 =  `${environment.packApiUrlv1_1}/shipments`;
    private BASE_URL_TOTES_V_1_0 = `${environment.packApiUrl}/totes`;

    private cache$: Observable<Order[]>;

    constructor(public http: HttpClient,
                public msalService: MsalService) {
                super(http, msalService);
    }

    public editDataDetails: any = [];
    public subject = new Subject<any>();
    private messageSource = new  BehaviorSubject(this.editDataDetails);
    currentMessage = this.messageSource.asObservable();

    updateToteName(toteHeaderLabel: ToteHeaderLabel) {
        this.messageSource.next(toteHeaderLabel);
    }

    getToteById(id: string) {
        return this.get<Tote>(`${this.BASE_URL_TOTES_V_1_0}/${id}`);
    }

    getOrderSynchronousCall(id: string) {
        const request = new XMLHttpRequest();
        request.open('GET', `${this.BASE_URL_PACKING_ORDERS}/${id}`, false);
        request.setRequestHeader('owd-api-key', environment.packApiKey);
        request.send();

        if (request.status === 200) {
            return request.response;
        } else {
            throw new Error('request failed');
        }
    }

    getOrder(id: string) {
        return this.get<Order>(`${this.BASE_URL_PACKING_ORDERS}/${id}`);
    }

    getCachedOrder(name: string, doRefresh: boolean = false) {
        if (!this.cache$ || doRefresh) {
            this.cache$ = this.requestOrderByName(name).pipe(
                shareReplay(1)
            );
        }
        return this.cache$;
    }

    getCachedOrderById(id: string, doRefresh: boolean = false) {
        if (!this.cache$ || doRefresh) {
            this.cache$ = this.get<Order[]>(`${this.BASE_URL_PACKING_ORDERS}/${id}`).pipe(
                map((res: any) => {
                    const order: Order[] = [];
                    if (res) {
                        order[0] = res;
                    }
                    return order;
                }),
                shareReplay(1)
            );
        }
        return this.cache$;
    }

    printPackslip(id: string)
    {
        this.http.get(`https://functionapponestorage.blob.core.windows.net/generated-packslip/${id}.png`,{
            responseType: 'blob'
           }).subscribe(
            (response) => { // download file
                var blob = new Blob([response], {type: 'image/png'});
                const blobUrl = URL.createObjectURL(blob);
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = blobUrl;
                document.body.appendChild(iframe);
                iframe.contentWindow.print();
            });
    }

    requestOrderByName(name: string) {
        // Pass along the tote name in the service to be used by other components header.menu-items.order-reference
        this.updateToteName({
            value: name,
            caption: 'header.menu-items.tote-name'
        });
        // Get order by TOTE Name , if no results look for order by id
        return this.get<Order[]>(`${this.BASE_URL_PACKING_ORDERS}/?toteName=${name.toUpperCase()}`).pipe(
            switchMap( result =>
                iif(
                    () => result.length === 0,
                    this.get<Order[]>(`${this.BASE_URL_PACKING_ORDERS}/${name}`).pipe(
                        map((res: any) => {
                            const order: Order[] = [];
                            if (res) {
                                order[0] = res;
                                this.updateToteName({
                                    value: '#' + name.substring(0, 8).toUpperCase(),
                                    caption: 'header.menu-items.order-reference'
                                });
                            }
                            return order;
                        }),
                        shareReplay(1)
                    ),
                    of(result)
                )
            ),
            shareReplay(1)
        );
    }

    requestOrderById(id: string) {
        return this.get<Order[]>(`${this.BASE_URL_PACKING_ORDERS}/${id}`);
    }

    packOrder(id: string, request: ShipementRequest) {
        return this.post<ShipementResponse>(`${this.BASE_URL_ORDERS_V_1_1}/${id}/pack`, request);
    }

    updateShipment(id: string, request: UpdateShipementRequest) {
        return this.patch<ShipementResponse>(`${this.BASE_URL_SHIPMENTS_V_1_1}/${id}`, request);
    }

    shipOrder(id: string) {
        return this.post<ShipementResponse>(`${this.BASE_URL_SHIPMENTS_V_1_1}/${id}/ship`, null);
    }

    setShipmentAttachments(id: string, parcelId: string, request: AttachmentRequest) {
        return this.post<AttachmentResponse>(`${this.BASE_URL_SHIPMENTS_V_1_1}/${id}/parcels/${parcelId}/attachments`, request);
    }

    getShipmentById(id: string) {
        return this.get<ShipementResponse>(`${this.BASE_URL_SHIPMENTS_V_1_1}/${id}`);
    }

    getSalesOrder(id: string) {
        return this.get<SalesOrder>(`${this.BASE_URL_ORDERS_V_1_1}/${id}`);
    }

    // Get Sales Order for Packing Slips and Commercial Invoices
    getSalesOrderSynchronousCall(id: string) {
        const request = new XMLHttpRequest();
        request.open('GET', `${this.BASE_URL_ORDERS_V_1_1}/${id}`, false);
        request.setRequestHeader('owd-api-key', environment.packApiKey);
        request.send();

        if (request.status === 200) {
            return request.response;
        } else {
            throw new Error('request failed');
        }
    }

    getShipmentByIdSynchronousCall(id: string) {
        const request = new XMLHttpRequest();
        request.open('GET', `${this.BASE_URL_SHIPMENTS_V_1_1}/${id}`, false);
        request.setRequestHeader('owd-api-key', environment.packApiKey);
        request.send();

        if (request.status === 200) {
            return request.response;
        } else {
            throw new Error('request failed');
        }
    }
}
