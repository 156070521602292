import { LocationStrategy } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DisplayMode, FooterDisplayMode, FooterService, HeaderMenuItemType, HeaderService } from '../../services';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, AfterViewInit {
  constructor(private headerService: HeaderService,
              private footerService: FooterService,
              private location: LocationStrategy) {
                history.pushState(null, null, window.location.href);
                this.location.onPopState(() => {history.pushState(null, null, window.location.href);
              });
            }

  ngOnInit(): void {
    this.setHeader();
    this.hideFooter();
  }

  ngAfterViewInit(): void {

  }

  setHeader() {
    const leftMenuItems = [{
      id: 'logo',
      type: HeaderMenuItemType.Logo,
      caption: '',
      data: environment.locationName
    }];

    this.headerService.setHeader({ title: '', mode: DisplayMode.Default, leftMenuItems,
      rightMenuItems: [], bottomMenuItems: [], topHeader: null, middleMenuItems: []});
  }

  hideFooter() {
    this.footerService.setFooter({ title: '',
      leftMenuItems: [],
      middleMenuItems: [],
      rightMenuItems: [],
      displayMode: FooterDisplayMode.Default});
  }
}
