import { Customer } from './customer.model';
import { Item } from './item.model';
import { OrderLine } from './order-line.viewmodel';
import { ProductHash } from './product-hash.model';
import { Product } from './product.model';

export class Order {
    constructor(
      public id: string,
      public accountId: string,
      public orderType: string,
      public channelId: string,
      public businessModel: string,
      public shippingMethod: ShippingMethod,
      public shippingMethodId: string,
      public packingInstructions: string,
      public shipEarliestOnDateTime: Date,
      public shipLatestOnDateTime: Date,
      public shipSLADateTime: Date,
      public labelReturnAddressOverride: LabelReturnAddressOverride,
      public packBenchmarkSeconds: number,
      public customer: Customer,
      public items: Item[],
      public products: Product[],
      public productHash: ProductHash[],
      public deliveryAddress: DeliveryAddress
    ) {

    }
  }

export class DeliveryAddress {
  constructor(
    public contact: string,
    public address1: string,
    public address2: string,
    public city: string,
    public country: string,
    public phone: string,
    public state: string,
    public postalCode: string,
    public company: string,
    public residentialFlag: boolean
  ) {

  }
}

export class ShippingMethod {
  constructor(
    public accountId: string,
    public code: string,
    public id: string,
    public name: string,
    public notes: string,
    // tslint:disable-next-line: variable-name
    public service_type_id: string
  ) {

  }
}

export class LabelReturnAddressOverride {
  constructor(
    public address1: string,
    public address2: string,
    public city: string,
    public company: string,
    public contact: string,
    public country: string,
    public phoneNumber: string,
    public postalCode: string,
    public state: string,
  ) {

  }
}
