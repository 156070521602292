import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AttachmentRequest } from '../../models/attachment-request';
import { ShipementRequest } from '../../models/shipement-request';
import { ShipementResponse } from '../../models/shipement-response';
import { UpdateShipementRequest } from '../../models/update-shipement-request';


export interface IOrderApiService {
    getOrder(id: string);
    getOrderSynchronousCall(id: string);
    getCachedOrder(name: string, doRefresh: boolean);
    getCachedOrderById(id: string, doRefresh: boolean);
    packOrder(id: string, request: ShipementRequest);
    updateShipment(id: string, request: UpdateShipementRequest);
    shipOrder(id: string);
    setShipmentAttachments(id: string, parcelId: string, request: AttachmentRequest);
}

export const ORDER_API_SERVICE = new InjectionToken<IOrderApiService>('IOrderApiService');
