import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BarcodeScannerComponent } from './components/barcode-scanner/barcode-scanner.component';
import { BarcodeScannerLightComponent } from './components/barcode-scanner-light/barcode-scanner-light.component';
import { StatusComponent } from './components/status/status.component';
import { TimerComponent } from './components/timer/timer.component';
import { SpinnerComponent } from '../core/components/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PutAwayComponent } from './components/put-away/put-away.component';
import { PreviewPackingInstructionsComponent } from './components/preview-packing-instructions/preview-packing-instructions.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MaterialModule } from '../material-module';
import { NgxPrintModule } from 'ngx-print';
import { GenerateLpnComponent } from './components/generate-lpn/generate-lpn.component';
import { GeneratePackSlipComponent } from './components/generate-pack-slip/generate-pack-slip.component';
import { PackSlipPreviewComponent } from './components/pack-slip-preview/pack-slip-preview.component';
import { StripHtmlPipe } from './pipes/strip-html-pipe';
import { CommInvoicePreviewComponent } from './components/comm-invoice-preview/comm-invoice-preview.component';
import { GenerateCommInvoiceComponent } from './components/generate-comm-invoice/generate-comm-invoice.component';
import { NgxBarcodeModule } from 'ngx-barcode';

@NgModule({
    declarations: [
        BarcodeScannerComponent,
        BarcodeScannerLightComponent,
        StatusComponent,
        TimerComponent,
        SpinnerComponent,
        PutAwayComponent,
        PreviewPackingInstructionsComponent,
        GenerateLpnComponent,
        GeneratePackSlipComponent,
        PackSlipPreviewComponent,
        GenerateCommInvoiceComponent,
        CommInvoicePreviewComponent,
        StripHtmlPipe
    ],
    imports: [
        NgxPrintModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        MatProgressSpinnerModule,
        QRCodeModule,
        MaterialModule,
        NgxBarcodeModule
    ],
    exports: [
        NgxPrintModule,
        CommonModule,
        ReactiveFormsModule,
        BarcodeScannerComponent,
        BarcodeScannerLightComponent,
        GenerateLpnComponent,
        GenerateCommInvoiceComponent,
        GeneratePackSlipComponent,
        StatusComponent,
        TimerComponent,
        PutAwayComponent,
        PreviewPackingInstructionsComponent,
        FormsModule,
        StripHtmlPipe
    ]
})
export class SharedKernelModule {
}
