import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { AuthService, FooterService } from '../../services';
import { HeaderMenuItem, HeaderService, HeaderMenuItemType, BottomHeaderMenuItem, DisplayMode,
    TopHeaderMenuItem, MenuTextAlign, State, MenuColor } from '../../services/header.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ProblemOrderApiService } from 'src/app/shared-kernel/services/problem-order/problem-order.api.service';
import { Locale } from 'src/app/shared-kernel/models/locale.model';
import { CookieService } from 'ngx-cookie-service';
import { LocaleApiService } from 'src/app/shared-kernel/services/locale/locale.api.service';
import { LocaleGetResponse } from 'src/app/shared-kernel/models/locale-get-response';
import { AccountApiService } from 'src/app/shared-kernel/services';
import { Account } from 'src/app/shared-kernel/models';
import { BenchMarkService } from '../../services/benchmark.service';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [ProblemOrderApiService, AccountApiService, BenchMarkService]
})

export class HeaderComponent implements OnInit, AfterViewInit {

    leftMenuItems: HeaderMenuItem[];
    rightMenuItems: HeaderMenuItem[];
    middleMenuItems: HeaderMenuItem[];
    bottomMenuItems: BottomHeaderMenuItem[];
    topMenuItem: TopHeaderMenuItem;
    notificationMenuItem: TopHeaderMenuItem = null;
    displayMode: DisplayMode;

    problemOrdersCount = 0;
    locales: Locale[] = null;
    title: string;
    isSerachBoxReseted = true;
    videoCaption: string;
    selectedLocale = null;
    state = State.Active;
    accounts: Account[] = null;
    isShowProblemOrderDetailsSelected = false;

    public get MenuTextAlign(): typeof MenuTextAlign {
        return MenuTextAlign;
    }

    public get MenuColor(): typeof MenuColor {
        return MenuColor;
    }

    public get HeaderMenuItemType(): typeof HeaderMenuItemType {
        return HeaderMenuItemType;
    }

    public get State(): typeof State {
        return State;
    }

    public get DisplayMode(): typeof DisplayMode {
        return DisplayMode;
    }

    constructor(private authService: AuthService,
                private router: Router,
                private benchMarkService: BenchMarkService,
                private accountService: AccountApiService,
                private cookieService: CookieService,
                private localeService: LocaleApiService,
                private problemOrderService: ProblemOrderApiService,
                private footerService: FooterService,
                private headerService: HeaderService,
                private location: Location) { }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.headerService.onSetHeader()
            .subscribe(header => {
                this.leftMenuItems = header.leftMenuItems;
                this.rightMenuItems = header.rightMenuItems;
                this.middleMenuItems = header.middleMenuItems;
                this.bottomMenuItems = header.bottomMenuItems;
                this.displayMode = header.mode;
                this.topMenuItem = header.topHeader;
                this.title = header.title;
                this.getProblemOrdersCount();
                this.getLocale();
            });

        this.headerService.onSetNotification()
            .subscribe(header => {
                this.notificationMenuItem = header;
            });

        this.footerService.onVideoProgressChanged()
            .subscribe(x => {
                if (x.title.length > 0) {
                    this.videoCaption = x.title + ' - ' + this.secondsToTime(x.progress) + ' / '
                        + this.secondsToTime(x.duration);
                } else {
                    this.videoCaption = '';
                }
            });
    }

    getTimeClock() {
        const benchMark = this.benchMarkService.getBenchMark();
        if (!benchMark) {
            return '00:00:00';
        }

        return this.secondsToTimeWithHours(benchMark.reduce((counter, x) => counter + x.seconds, 0));
    }

    getOrders() {
        const benchMark = this.benchMarkService.getBenchMark();
        if (!benchMark) {
            return '0';
        }

        return benchMark.length;
    }

    getBoxes() {
        const benchMark = this.benchMarkService.getBenchMark();
        if (!benchMark) {
            return '0';
        }

        return benchMark.reduce((counter, x) => counter + x.boxes, 0);
    }

    getUserName(): string {
        return this.authService.getCurrentUser()?.name;
    }

    secondsToTime(secs: number): string {
        const hours = Math.floor(secs / (60 * 60));

        const divisorForMinutes = secs % (60 * 60);
        const minutes = Math.floor(divisorForMinutes / 60);

        const divisorForSeconds = divisorForMinutes % 60;
        const seconds = Math.ceil(divisorForSeconds);

        return ('0' + minutes).slice(-2)  + ':' + ('0' + seconds).slice(-2);
    }

    secondsToTimeWithHours(secs: number): string {
        const hours = Math.floor(secs / (60 * 60));

        const divisorForMinutes = secs % (60 * 60);
        const minutes = Math.floor(divisorForMinutes / 60);

        const divisorForSeconds = divisorForMinutes % 60;
        const seconds = Math.ceil(divisorForSeconds);

        return ('0' + hours).slice(-2)  + ':' + ('0' + minutes).slice(-2)  + ':' + ('0' + seconds).slice(-2);
    }

    logOut() {
        this.benchMarkService.clearBenchMark();
        this.authService.logOut();
    }

    showProblemOrderDetails(id: string) {
        this.isShowProblemOrderDetailsSelected = !this.isShowProblemOrderDetailsSelected;
        this.headerService.clickDefaultButton({id});
    }

    getProblemOrdersCount() {
        // if (this.rightMenuItems?.filter(x => x.type === HeaderMenuItemType.ProblemOrder).length > 0) {
        //     this.problemOrderService.getOrders(1, 1).subscribe((data) => {
        //         this.problemOrdersCount = data.totalRows;
        //    });
        // } else {
        //     this.problemOrdersCount = 0;
        // }
    }

    getLocale() {
        const localeIndex = this.rightMenuItems?.findIndex(x => x.type === HeaderMenuItemType.Locale);
        if (localeIndex !== -1) {
            if (!this.locales) {
                this.localeService.getCashedLocales().subscribe(result => {
                    this.locales = result.locales;

                    if (this.locales && !this.rightMenuItems[localeIndex].data) {
                        this.selectedLocale = this.locales[0];
                        this.headerService.setLocale(this.selectedLocale);
                    }
                });
            }
        }
    }

    selectAccount(buttonId: string, accountId: string, accountName: string) {
        (document.getElementById('searchInput') as HTMLInputElement).value = '';
        this.headerService.clickDefaultButton({id: buttonId, data: accountId, caption: accountName});
        this.accounts = null;
    }

    navigate(url) {
        if (url) {
            // window.location.replace(url);
            this.router.navigateByUrl(url);
        }
    }

    closeTopHeader() {
        this.topMenuItem = null;
        this.headerService.setHeader({ title: this.title, mode: this.displayMode, leftMenuItems: this.leftMenuItems,
            rightMenuItems: this.rightMenuItems, bottomMenuItems: this.bottomMenuItems, topHeader: this.topMenuItem});
    }

    closeNotificationHeader() {
        this.notificationMenuItem = null;
        this.headerService.setNotification(null);
        this.headerService.hideNotification();
    }

    searchFocusOut() {
        setTimeout(() => {
            this.isSerachBoxReseted = true;
        }, 300);
    }

    searchFocusIn() {
        this.isSerachBoxReseted = false;
    }

    searchReset() {
        this.accounts = null;
        this.isSerachBoxReseted = true;
        document.getElementById('searchInput').blur();
        (document.getElementById('searchInput') as HTMLInputElement).value = '';
        this.headerService.setFilter(null);
    }

    searchClick(event) {
        this.isSerachBoxReseted = false;
    }

    searchChange(menu: HeaderMenuItem, event) {
        if (event.code === 'Enter' && menu.type === HeaderMenuItemType.SearchAccount) {
            this.accountService.getAccounts((document.getElementById('searchInput') as HTMLInputElement).value)
                .subscribe(result => {
                this.accounts = result;
            });
        } else {
            this.headerService.setFilter((document.getElementById('searchInput') as HTMLInputElement).value);
        }
    }

    clickDefaultButton(buttonId: string) {
        this.headerService.clickDefaultButton({id: buttonId, data: ''});
    }

    setLocale(locale: Locale) {
        this.selectedLocale = locale;
        this.headerService.setLocale(locale);
    }

    setState(state: State) {
        this.state = state;
        this.headerService.setState(state);
    }
}
