import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root'})

export class LocalStorageService {
    constructor(
        private authService: AuthService
    ) {

    }

    public getLocalStorage(type: LocalStorageType): any {
        const item = JSON.parse(localStorage.getItem(type));
        if (item) {
            const account = this.authService.getCurrentUser();
            if (Number(account.idTokenClaims.auth_time) * 1000 > item.time) {
                localStorage.removeItem(type.toString());
                return null;
            }
            return item.value;
        }

        return null;
    }

    public setLocalStorage(type: LocalStorageType, data: any) {
        const item = {
            value: data,
            time: Number(new Date())
        };
        localStorage.setItem(type.toString(), JSON.stringify(item));
    }
}

export enum LocalStorageType {
    PackingMaterials = 'packing-materials'
}
