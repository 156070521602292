import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  @Input() time: number;

  constructor(private router: Router) {
      setInterval(() => {
        this.time = this.time + 1;
      }, 1000);
  }

  ngOnInit(): void {

  }

  secondsToTime(secs: number): string {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    return ('0' + minutes).slice(-2)  + ':' + ('0' + seconds).slice(-2);
  }
}
