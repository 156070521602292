import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOrderApiService } from '..';
import { Order } from '../../models';
import { IssueCategory } from '../../models/issue-category.model';
import { IssueTypeGetResponse } from '../../models/issue-type-get-response.model';
import { ProblemOrderCreateResponse } from '../../models/problem-order-create-response.model';
import { ProblemOrderCreateRequest } from '../../models/problem-order-create-request.model';
import { ProblemOrderGetResponse } from '../../models/problem-order-get-response.model';
import { ProblemOrder } from '../../models/problem-order.model';
import { BaseApiService } from '../base.api.service';
import { IssueType } from '../../models/issue-type.model';
import { IssueResponse } from '../../models/issue-response.model';
import { IssueRequest } from '../../models/issue-request.model';
import { IProblemOrderApiService } from './problem-order.api.interface';
import { ResolutionRequest } from '../../models/resolution-request.model';
import { ResolutionResponse } from '../../models/resolution-response.model';
import { ProblemOrderPutRequest } from '../../models/problem-order-put-request.model';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class ProblemOrderApiService extends BaseApiService implements IProblemOrderApiService {
    [x: string]: any;
    private BASE_URL = `${environment.packApiUrl}/apps/pack/problem-orders`;

    constructor(public http: HttpClient,
                public msalService: MsalService) {
                super(http, msalService);
    }

    getOrders(pageNumber: number = 1, pageSize: number = 1, sortDirection: string = 'desc',
              sortBy: string = 'priority', isResolved: boolean = false) {
        return this.get<ProblemOrderGetResponse>
            (`${this.BASE_URL}/?pageNumber=${pageNumber}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&isResolved=${isResolved}`);
    }

    getOrder(orderId: string) {
        return this.get<ProblemOrder>(`${this.BASE_URL}/${orderId}`);
    }

    putOrder(orderId: string, request: ProblemOrderPutRequest) {
        // super.setAuthData(request);
        return this.put<ProblemOrder>(`${this.BASE_URL}/${orderId}`, request);
    }

    createOrder(request: ProblemOrderCreateRequest) {
        // super.setAuthData(request);
        return this.post<ProblemOrderCreateResponse>(`${this.BASE_URL}`, request);
    }

    getIssues(orderId: string) {
        return this.get<IssueResponse[]>(`${this.BASE_URL}/${orderId}/issues`);
    }

    getIssue(orderId: string, issueId: string) {
        return this.get<IssueResponse>(`${this.BASE_URL}/${orderId}/issues/${issueId}`);
    }

    putIssue(orderId: string, issueId: string, request: IssueRequest) {
        super.setAuthData(request);
        return this.put<IssueResponse>(`${this.BASE_URL}/${orderId}/issues/${issueId}`, request);
    }

    createIssue(orderId: string, request: IssueRequest) {
        super.setAuthData(request);
        return this.post<IssueResponse>(`${this.BASE_URL}/${orderId}/issues/`, request);
    }

    deleteIssue(orderId: string, issueId: string) {
        return this.delete<IssueResponse>(`${this.BASE_URL}/${orderId}/issues/${issueId}`);
    }

    getResolutions(orderId: string, issueId: string) {
        return this.get<Order[]>(`${this.BASE_URL}/${orderId}/issues/${issueId}`);
    }

    getResolution(orderId: string, issueId: string, resolutionId: string) {
        return this.get<Order[]>(`${this.BASE_URL}/${orderId}/issues/${issueId}/?id=${resolutionId}`);
    }

    createResolution(orderId: string, issueId: string, request: ResolutionRequest) {
        super.setAuthData(request);
        return this.post<ResolutionResponse[]>(`${this.BASE_URL}/${orderId}/issues/${issueId}/resolutions`, request);
    }

    putResolution(orderId: string, issueId: string, resolutionId: string, request: ResolutionRequest) {
        super.setAuthData(request);
        throw new Error('Method not implemented.');
    }

    getTypes() {
        return this.get<IssueTypeGetResponse>(`${this.BASE_URL}/issues/types`);
    }

    getType(typeId: string) {
        return this.get<Order[]>(`${this.BASE_URL}/issues/types/${typeId}`);
    }

    getCategories() {
        return this.get<Order[]>(`${this.BASE_URL}`);
    }

    getCategory(categoryId: string) {
        return this.get<Order[]>(`${this.BASE_URL}`);
    }
}
