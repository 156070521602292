import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class FooterService {

  constructor() {}

  protected setFooterSubject = new Subject<{ title: string
    leftMenuItems: FooterMenuItem[],
    middleMenuItems: FooterMenuItem[],
    rightMenuItems: FooterMenuItem[]
    displayMode: FooterDisplayMode }>();

  protected clickDefaultButtonSubject = new Subject<string>();

  protected videoProgressChangedSubject = new Subject<{ progress: number, duration: number, title: string}>();

  public setFooter(obj: { title: string
    leftMenuItems: FooterMenuItem[],
    middleMenuItems: FooterMenuItem[],
    rightMenuItems: FooterMenuItem[],
    displayMode: FooterDisplayMode}) {
    this.setFooterSubject.next(obj);
  }

  public clickDefaultButton(buttonId: string) {
    this.clickDefaultButtonSubject.next(buttonId);
  }

  public videoProgressChanged(obj: { progress: number, duration: number, title: string }) {
    this.videoProgressChangedSubject.next(obj);
  }

  public onSetFooter(): Observable<{ title: string
    leftMenuItems: FooterMenuItem[],
    middleMenuItems: FooterMenuItem[],
    rightMenuItems: FooterMenuItem[],
    displayMode: FooterDisplayMode}> {
    return this.setFooterSubject.asObservable();
  }

  public onClickDefaultButton(): Observable<string> {
    return this.clickDefaultButtonSubject.asObservable();
  }

  public onVideoProgressChanged(): Observable<{ progress: number, duration: number, title: string }> {
    return this.videoProgressChangedSubject.asObservable();
  }
}

export class FooterMenuItem {
    id: string;
    caption?: string;
    type?: FooterMenuItemType;
    linkStatus?: LinkStatus;
    imageUrl?: string;
    videoUrl?: string;
    text?: string;
}

export enum FooterMenuItemType {
  Link = 1,
  PauseButton = 4,
  BackButton = 5,
  NextButton = 6,
  DefaultButton = 7,
  DefaultWhiteButton = 8,
  ReplayButton = 9,
  DefaultButtonWithPackingSlip = 10
}

export enum LinkStatus {
  Default = 0,
  Watched = 1,
  Selected = 2
}

export enum FooterDisplayMode {
  Default = 1,
  Video = 2
}
