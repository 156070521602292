import { async } from '@angular/core/testing';
import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { FooterMenuItem, FooterService } from '../../services/footer.service';
import { BottomHeaderMenuItem, HeaderMenuItem, HeaderService, TopHeaderMenuItem } from '../../services/header.service';
import * as onScan from 'onscan.js';
import { CookieService } from 'ngx-cookie-service';
import { SetupMode } from 'src/app/shared-kernel/enums/setup-mode.enum';
import { BarcodeService } from '../../services/barcode.service';
import { Subscription } from 'rxjs';
import { LoggingService } from '../../services/logging.service';



@Component({
    selector: 'app-main-content',
    templateUrl: './main-content.component.html',
    styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit, AfterViewInit, OnDestroy {
    leftMenuItems: HeaderMenuItem[];
    bottomMenuItems: BottomHeaderMenuItem[];
    topMenuItem: TopHeaderMenuItem;
    rightMenuItems: FooterMenuItem[];
    notification: TopHeaderMenuItem;

    setHeaderSubscription: Subscription;
    setFooterSubscription: Subscription;
    setNotificationSubscription: Subscription;

    constructor(
        private headerService: HeaderService,
        private loggingService: LoggingService,
        private barcodeService: BarcodeService,
        private cookieService: CookieService,
        private footerService: FooterService) { }

    ngOnInit(): void {
        // Enable scan events for the entire document
        onScan.attachTo(document, {
            keyCodeMapper(oEvent) {
                // Look for special keycodes or other event properties specific to
                // your scanner
                if (oEvent.which.toString() === '191') {
                    return '/';
                }
                if (oEvent.which.toString() === '189') {
                    return '-';
                }
                // Fall back to the default decoder in all other cases
                return onScan.decodeKeyEvent(oEvent);
            }
        });

        // Register event listener
        document.addEventListener('scan', (event: any) => {
            this.loggingService.log(event.detail.scanCode.trim() + ' x ' + event.detail.qty);
            this.barcodeService.barcodeScanned(event.detail.scanCode.trim());
        });

        // Set defalut station mode
        const stationMode = this.cookieService.get('station-mode');
        if (!stationMode) {
          this.cookieService.set('station-mode', SetupMode.ManualPack.toString(), 9999);
        }
    }

    ngAfterViewInit(): void {
        this.setFooterSubscription = this.footerService.onSetFooter()
            .subscribe(footer => {
                this.rightMenuItems = footer.rightMenuItems;
            });

        this.setHeaderSubscription = this.headerService.onSetHeader()
            .subscribe(header => {
                this.leftMenuItems = header.leftMenuItems;
                this.bottomMenuItems = header.bottomMenuItems;
                this.topMenuItem = header.topHeader;
            });

        this.setNotificationSubscription = this.headerService.onSetNotification()
            .subscribe(notification => {
                this.notification = notification;
                this.loggingService.log(notification);
            });
    }

    ngOnDestroy(): void {
        this.setHeaderSubscription.unsubscribe();
        this.setFooterSubscription.unsubscribe();
        this.setNotificationSubscription.unsubscribe();
    }
}
