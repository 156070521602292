import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root'})
export class LoggingService {

  constructor() {}

  public log(message: any) {
    if (!environment.production) {
        console.log(message);
    }
  }

}
