import { LocationStrategy } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { FooterDisplayMode, FooterMenuItem, FooterMenuItemType, FooterService, LinkStatus } from 'src/app/core/services/footer.service';
import { BottomHeaderMenuItem, DisplayMode, HeaderMenuItem, HeaderMenuItemType, HeaderService,
  MenuTextAlign,
  TopHeaderMenuItem } from 'src/app/core/services/header.service';
import { LoggingService } from 'src/app/core/services/logging.service';
import { ScanningType } from 'src/app/shared-kernel/components/barcode-scanner/barcode-scanner.component';
import { SetupMode } from 'src/app/shared-kernel/enums/setup-mode.enum';
import { PackingMaterial } from 'src/app/shared-kernel/models';
import { Instruction } from 'src/app/shared-kernel/models/instruction';
import { InstructionStep } from 'src/app/shared-kernel/models/instruction-step.model';
import { InstrutionApiService } from 'src/app/shared-kernel/services/instruction/instruction.api.service';
import { PackingMaterialApiService } from 'src/app/shared-kernel/services/packing-material/packing-material.api.service';

@Component({
  selector: 'app-preview-packing-instructions',
  templateUrl: './preview-packing-instructions.component.html',
  styleUrls: ['./preview-packing-instructions.component.scss'],
  providers: [InstrutionApiService]
})
export class PreviewPackingInstructionsComponent implements OnInit, AfterViewInit, OnDestroy {
  leftMenuItems: HeaderMenuItem[] = [{
    id: 'itemName',
    type: HeaderMenuItemType.Title,
    caption: 'Item Name',
    data: ''
  },
  {
    id: 'timer',
    type: HeaderMenuItemType.Timer,
    caption: '',
    data: ''
  }];

  rightMenuItems: HeaderMenuItem[] = [{
    id: 'close',
    type: HeaderMenuItemType.Close,
    caption: '',
    data: '',
    url: '/mvp/packing-instructions/' + this.activatedRoute.snapshot.paramMap.get('instructionId')
  },
  {
    id: 'items',
    type: HeaderMenuItemType.SubElement,
    caption: 'header.menu-items.items',
    data: '10'
  },
  {
    id: 'boxes',
    type: HeaderMenuItemType.SubElement,
    caption: 'header.menu-items.boxes',
    data: '10'
  },
  {
    id: 'benchMark',
    type: HeaderMenuItemType.Element,
    caption: 'header.menu-items.bench-mark',
    data: '00:00'
  }];

  bottomMenuItems: BottomHeaderMenuItem[] = [];

  leftFooterMenuItems: FooterMenuItem[] = [];

  middleFooterMenuItems: FooterMenuItem[] = [];

  // middleFooterMenuItems: FooterMenuItem[] = [
  // {
  //   id: 'step1',
  //   caption: 'step1',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step3',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // },
  // {
  //   id: 'step2',
  //   caption: 'step2',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step2'
  // },
  // {
  //   id: 'step3',
  //   caption: 'step3',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step1',
  //   videoUrl: '../../../../assets/videos/videoplayback.mp4'
  // },
  // {
  //   id: 'step4',
  //   caption: 'step4',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step4',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // },
  // {
  //   id: 'step5',
  //   caption: 'step5',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step5',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // },
  // {
  //   id: 'step6',
  //   caption: 'step6',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step6',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // },
  // {
  //   id: 'step7',
  //   caption: 'step7',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step7',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // },
  // {
  //   id: 'step8',
  //   caption: 'step8',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step8',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // },
  // {
  //   id: 'step9',
  //   caption: 'step9',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step9',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // },
  // {
  //   id: 'step10',
  //   caption: 'step10',
  //   type: FooterMenuItemType.Link,
  //   linkStatus: LinkStatus.Default,
  //   text: 'Packing Instruction text goes here step10',
  //   imageUrl: '../../../../assets/images/packing-instructions.jpg'
  // }];

  rightFooterMenuItems: FooterMenuItem[] = [
    {
      id: 'back',
      caption: 'packing-instructions.buttons.back',
      type: FooterMenuItemType.BackButton
    },
    {
      id: 'next',
      caption: 'packing-instructions.buttons.next',
      type: FooterMenuItemType.NextButton
    }
  ];

  topMenuItem: TopHeaderMenuItem = {
    id: 'message',
    caption: 'Preview Mode Active!',
    align: MenuTextAlign.Center,
    showCloseButton: false
  };

  isSidebarClosed = false;
  selectedMenuItem: FooterMenuItem;
  selectedStep: InstructionStep = null;
  steps: InstructionStep[] = [];
  instruction: Instruction = null;
  packingMaterials: PackingMaterial[] = null;

  clickDefaultButtonSubscription: Subscription;

  public get LinkStatus(): typeof LinkStatus {
    return LinkStatus;
  }

  @ViewChild('video') elVideo: ElementRef;

  constructor(private headerService: HeaderService,
              private loggingService: LoggingService,
              private instructionService: InstrutionApiService,
              private router: Router,
              private packingMaterialService: PackingMaterialApiService,
              private activatedRoute: ActivatedRoute,
              private location: LocationStrategy,
              private footerService: FooterService) {
                history.pushState(null, null, window.location.href);
                this.location.onPopState(() => {history.pushState(null, null, window.location.href);
              });
            }

  ngOnInit(): void {
    this.hideFooter();
    this.getInstruction();
  }

  ngAfterViewInit(): void {
    this.clickDefaultButtonSubscription = this.footerService.onClickDefaultButton()
      .subscribe(buttonId => {
        if (buttonId === 'pause') {
          this.pause();
        } else if (buttonId === 'back') {
          this.back();
        } else if (buttonId === 'next') {
          this.next();
        } else if (buttonId === 'confirmPacking') {

        } else if (buttonId === 'replay') {
          this.replay();
        }
      });
  }

  ngOnDestroy(): void {
    this.clickDefaultButtonSubscription.unsubscribe();
  }

  getInstruction() {
    this.instructionService.getInstruction(this.activatedRoute.snapshot.paramMap.get('instructionId')).subscribe(result => {
      this.instruction = result;
      this.getPackingMaterials(this.instruction.accountId);
    });
  }

  getPackingMaterials(accountId: string) {
    this.packingMaterialService.getCashedPackingMaterial()
      .subscribe(result => {
        this.packingMaterials = result;
        this.getSteps();
      });
  }

  getSteps() {
    this.instructionService
      .getInstructionStepList(this.activatedRoute.snapshot.paramMap.get('instructionId'))
      .subscribe(result => {
        this.loggingService.log(result);
        this.steps = result.steps;

        this.steps?.forEach(step => {
          step.supplies?.forEach(supply => {
            supply.name = this.packingMaterials?.filter(x => x.id.toLowerCase() === supply.id.toLowerCase())[0]?.name;
            supply.description = this.packingMaterials?.filter(x => x.id.toLowerCase() === supply.id.toLowerCase())[0]?.description;
          });
        });

        const tempSteps: FooterMenuItem[] = [];
        this.steps.forEach(step => {
          tempSteps.push({
            id: step.id,
            caption: step.id,
            text: step.description,
            type: FooterMenuItemType.Link,
            linkStatus: LinkStatus.Default
          });
        });
        this.middleFooterMenuItems = tempSteps;

        if (this.steps?.length > 0) {
          this.next();
        }
      });
  }

  showHeader() {
    this.leftMenuItems = [{
      id: 'itemName',
      type: HeaderMenuItemType.Title,
      caption: this.instruction.title,
      data: ''
    },
    {
      id: 'timer',
      type: HeaderMenuItemType.Timer,
      caption: '',
      data: ''
    }];

    this.headerService.setHeader({ title: '', mode: DisplayMode.Video, leftMenuItems: this.leftMenuItems,
      rightMenuItems: this.rightMenuItems, bottomMenuItems: this.bottomMenuItems, topHeader: this.topMenuItem});
  }

  showFooter() {
    this.footerService.setFooter({ title: '',
      leftMenuItems: this.leftFooterMenuItems,
      middleMenuItems: this.middleFooterMenuItems,
      rightMenuItems: this.rightFooterMenuItems,
      displayMode: FooterDisplayMode.Video});
  }

  hideFooter() {
    this.footerService.setFooter({ title: '',
      leftMenuItems: [],
      middleMenuItems: [],
      rightMenuItems: [],
      displayMode: FooterDisplayMode.Video});
  }

  getSelectedStepSupplies() {
    return  this.steps[this.steps.findIndex(x => x.id === this.selectedMenuItem?.id)]?.supplies;
  }

  next() {
    this.footerService.videoProgressChanged({ progress: 0, duration: 0, title: '' });

    const index = this.middleFooterMenuItems.findIndex(i => i.linkStatus === LinkStatus.Selected);

    if (index !== -1) {
      this.middleFooterMenuItems[index].linkStatus = LinkStatus.Watched;
    }

    if (index < this.middleFooterMenuItems.length - 1) {
      this.middleFooterMenuItems[index + 1].linkStatus = LinkStatus.Selected;
      this.selectedMenuItem = this.middleFooterMenuItems[index + 1];

      if (this.middleFooterMenuItems[index + 1].videoUrl) {
        this.leftFooterMenuItems = [{
          id: 'pause',
          caption: '',
          type: FooterMenuItemType.PauseButton
        }];
      } else {
        this.leftFooterMenuItems = [];
      }
    } else {
      this.rightFooterMenuItems = [{
        id: 'confirmPacking',
        caption: 'packing-instructions.buttons.confirm-packing',
        type: FooterMenuItemType.DefaultButton
      }];

      this.leftFooterMenuItems = [{
        id: 'replay',
        caption: '',
        type: FooterMenuItemType.ReplayButton
      }];
    }

    this.showFooter();
    this.showHeader();
  }

  back() {
    this.footerService.videoProgressChanged({  progress: 0, duration: 0, title: '' });

    const index = this.middleFooterMenuItems.findIndex(i => i.linkStatus === LinkStatus.Selected);

    if (index > 0) {
      this.middleFooterMenuItems[index].linkStatus = LinkStatus.Default;
      this.middleFooterMenuItems[index - 1].linkStatus = LinkStatus.Selected;
      this.selectedMenuItem = this.middleFooterMenuItems[index - 1];

      if (this.middleFooterMenuItems[index - 1].videoUrl) {
        this.leftFooterMenuItems = [{
          id: 'pause',
          caption: '',
          type: FooterMenuItemType.PauseButton
        }];
      } else {
        this.leftFooterMenuItems = [];
      }
    } else {

    }

    this.showFooter();
    this.showHeader();
  }

  replay() {
    this.middleFooterMenuItems.forEach(x => x.linkStatus = LinkStatus.Default);
    this.rightFooterMenuItems = [
      {
        id: 'back',
        caption: 'packing-instructions.buttons.back',
        type: FooterMenuItemType.BackButton
      },
      {
        id: 'next',
        caption: 'packing-instructions.buttons.next',
        type: FooterMenuItemType.NextButton
      }
    ];

    this.next();
  }

  pause() {
    const element = this.elVideo.nativeElement;
    if (element.paused) {
      element.play();
    }
    else {
      element.pause();
    }
  }

  onTimeUpdate(value) {
    const duration = Math.floor(value.target.duration);
    const currentTime = Math.floor(value.target.currentTime);

    this.footerService.videoProgressChanged({
      progress: currentTime,
      duration,
      title: this.selectedMenuItem.caption
    });
  }

  triggerSideBar() {
    this.isSidebarClosed = !this.isSidebarClosed;
  }
}
