import { OrderLine } from './order-line.viewmodel';

export class PackingMaterial {
    public isSelected?: boolean;
    public isAdded?: boolean;
    public isRemoved?: boolean;
    public selectedQuantity?: number;

    constructor(
        public accountId: string,
        public id: string,
        public state: string,
        // tslint:disable-next-line: variable-name
        public number: string,
        public name: string,
        public description: string,
        public weight: number,
        public weightUom: string,
        public dimensionsLength: number,
        public dimensionsWidth: number,
        public dimensionsHeight: number,
        public dimensionsUom: string,
        public notes: string,
        public physicalPackaging: string,
        public quantity?: number
    ) {

    }
  }
