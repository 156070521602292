import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BroadcastService, MsalService} from '@azure/msal-angular';

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js

@Injectable()
export class AuthService {
    constructor(private http: HttpClient,
                private router: Router,
                private msalService: MsalService,
                private cookieService: CookieService) {}

    logIn(login: string, password: string): void {

    }

    logOut(): void {
        this.msalService.logout();
    }

    getCurrentUser() {
        return this.msalService.getAccount();
    }

    get isLoggedIn(): boolean {

        return true;
    }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        return true;
    }
}
