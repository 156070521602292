<div class="pack-slip" style="width: 21cm;height: 27.4cm;margin: 0;display: none;position: relative;" id="pack-slip-print">
  <div class="header" style="height: 34px; position: relative;">
    <h1
      style="font-family: Roboto;display: block;font-style: normal;font-weight: 500;font-size: 21px;line-height: 28px;color: #404040;">
      {{ this.getAccountName() }}</h1>
    <div class="right" style="position: absolute;height: 22px; top: 0; right: 0">
      <img *ngIf="this.account?.logoUrl" style="display: block;" width="125" height="155"
        src='{{ this.getAccountLogo() }}'>
    </div>
  </div>
  <div [innerHtml]="html">
  </div>
  <div class="barcode-footer">
    <ngx-barcode [bc-element-type]="'svg'" [bc-margin]="0" [bc-margin-top]="0" [bc-margin-bottom]="0"
      [bc-margin-left]="0" [bc-margin-right]="0" [bc-width]="1" [bc-height]="50" [bc-line-color]="'#585858'"
      [bc-value]="this.order.id" [bc-display-value]="true" [bc-font-size]="17"></ngx-barcode>
  </div>
</div>