import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { IScanningOrderApiService } from '../scanning-order.api.interface';


@Injectable()
export class ScanningOrderApiServiceStub implements IScanningOrderApiService {
    constructor() {

    }

    getOrder() {
        throw new Error('Method not implemented.');
    }
}
