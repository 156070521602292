import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { Product } from '../../models';
import { BaseApiService } from '../base.api.service';
import { IProductApiService } from './product.api.interface';

@Injectable()
export class ProductApiService extends BaseApiService implements IProductApiService {
    private BASE_URL_V_1_1 = `${environment.packApiUrlv1_1}/products`;

    constructor(public http: HttpClient,
                public msalService: MsalService) {
                super(http, msalService);
    }

    getProduct(productId: string, accountId: string) {
        return this.get<Product>(`${this.BASE_URL_V_1_1}/${productId.toLowerCase()}?accountId=${accountId.toLowerCase()}`);
    }

    getProductSynchronousCall(productId: string, accountId: string) {
        const request = new XMLHttpRequest();
        request.open('GET', `${this.BASE_URL_V_1_1}/${productId.toLowerCase()}?accountId=${accountId.toLowerCase()}`, false);
        request.setRequestHeader('owd-api-key', environment.packApiKey);
        request.send();

        if (request.status === 200) {
            return request.response;
        } else {
            throw new Error('request failed');
        }
    }
}
