import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../base.api.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ILocaleApiService } from './locale.api.interface';
import { Locale } from '../../models/locale.model';
import { LocaleGetResponse } from '../../models/locale-get-response';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { LoggingService } from 'src/app/core/services/logging.service';

@Injectable({ providedIn: 'root'})
export class LocaleApiService extends BaseApiService implements ILocaleApiService {
    private BASE_URL = `${environment.packApiUrl}/apps/pack/locale`;

    private locales$: Observable<LocaleGetResponse>;

    constructor(public http: HttpClient,
                public loggingService: LoggingService,
                public msalService: MsalService) {
                super(http, msalService);
    }

    getCashedLocales(): Observable<LocaleGetResponse> {
        if (!this.locales$) {
            this.loggingService.log('getLocales');
            this.locales$ = this.get<LocaleGetResponse>(`${this.BASE_URL}`).pipe(shareReplay(1));
        }
        return this.locales$;
    }
}
