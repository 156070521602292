import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { IMvpApiService } from '../mvp.api.interface';


@Injectable()
export class MvpApiServiceStub implements IMvpApiService {
    constructor() {

    }

    getOrder() {
        throw new Error('Method not implemented.');
    }
}
