import { forEach } from '@angular-devkit/schematics';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Pipe } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOrderApiService } from '..';
import { Order, PackingMaterial } from '../../models';
import { BaseApiService } from '../base.api.service';
import { IPackingMaterialApiService } from './packing-material.api.interface';
import { map, switchMap, mergeMap, shareReplay, tap, concatMap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { LocalStorageService, LocalStorageType } from 'src/app/core/services/local-storage.service';
import { LoggingService } from 'src/app/core/services/logging.service';

@Injectable({ providedIn: 'root'})
export class PackingMaterialApiService extends BaseApiService implements IPackingMaterialApiService {
    private BASE_URL = `${environment.packApiUrl}/configuration/packing-materials`;
    private BASE_URL_INVENTORY = `${environment.packApiUrl}/inventories/`;

    private dunnage$: Observable<PackingMaterial[]>;
    private packingMaterialInventories$: Observable<any>;

    constructor(public http: HttpClient,
                public loggingService: LoggingService,
                public localStorage: LocalStorageService,
                public msalService: MsalService) {
                super(http, msalService);
    }

    getPackingMaterial(accountId: string): Observable<PackingMaterial[]> {
        return this.get<PackingMaterial[]>(`${this.BASE_URL}/?accountId=${accountId}`);
    }

    getCashedPackingMaterial(): Observable<PackingMaterial[]> {
        const cashedPackingMaterials = this.localStorage.getLocalStorage(LocalStorageType.PackingMaterials);
        if (cashedPackingMaterials) {
            this.loggingService.log('get cashed packing-materials');
            return of(cashedPackingMaterials);
        }

        return this.get<PackingMaterial[]>(`${this.BASE_URL}/?locationId=${environment.locationId}`).pipe(
            concatMap((packingMaterials: PackingMaterial[], index) => {
                return forkJoin(
                    packingMaterials.map((packingMaterial: PackingMaterial) => {
                        return this.getPackingMaterialQuantity(packingMaterial);
                    })
                );
            }), tap(result => {
                this.loggingService.log('get packing-materials');
                console.log(result);
                this.localStorage.setLocalStorage(LocalStorageType.PackingMaterials, result);
            })
        );
    }

    getCashedDunnage(): Observable<PackingMaterial[]> {
        if (!this.dunnage$) {
            this.dunnage$ = this.get<PackingMaterial[]>(`${this.BASE_URL}/?level=1`)
                .pipe(shareReplay(1));
        }
        return this.dunnage$;
    }

    getPackingMaterialInventories(accountId: string, productId: string): any {
        return this.get<any>(`${this.BASE_URL_INVENTORY}/?accountId=${accountId}&productId=${productId}&locationId=${environment.locationId}`);
    }

    private getPackingMaterialQuantity(packingMaterial: PackingMaterial): PackingMaterial[] {
        return this.getPackingMaterialInventories(packingMaterial.accountId, packingMaterial.id).pipe(
            map((invPackingMaterial: any) => {
                this.loggingService.log(invPackingMaterial);
                if (invPackingMaterial.inventories.length > 0) {
                    let sumQuantities = 0;
                    invPackingMaterial.inventories.forEach(item => {
                        console.log(item.productId);
                        console.log(item.quantityAvailable);
                        sumQuantities += item.quantityAvailable;
                    });
                    packingMaterial.quantity = sumQuantities;
                }
                return packingMaterial;
            }));
    }
}
