import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { State } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { Instruction } from '../../models/instruction';
import { InstructionCreateRequest } from '../../models/instruction-create-request';
import { InstructionCreateResponse } from '../../models/instruction-create-response';
import { InstructionListGetResponse } from '../../models/instruction-list-get-response';
import { InstructionProductCreateRequest } from '../../models/instruction-product-create-request';
import { InstructionProductUpdateRequest } from '../../models/instruction-product-update-request';
import { InstructionStepCreateRequest } from '../../models/instruction-step-create-request';
import { InstructionStepCreateResponse } from '../../models/instruction-step-create-response';
import { InstructionStepGetResponse } from '../../models/instruction-step-get-response';
import { InstructionStepSupplyCreateRequest } from '../../models/instruction-step-supply-create-request';
import { InstructionStepSupplyUpdateRequest } from '../../models/instruction-step-supply-update-request';
import { InstructionStepUpdateRequest } from '../../models/instruction-step-update-request';
import { InstructionStep } from '../../models/instruction-step.model';
import { InstructionUpdateRequest } from '../../models/instruction-update-request';
import { BaseApiService } from '../base.api.service';
import { IInstructionApiService } from './instruction.api.interface';

@Injectable()
export class InstrutionApiService extends BaseApiService implements IInstructionApiService {
    private BASE_URL = `${environment.packApiUrl}/apps/pack/instructions`;

    constructor(public http: HttpClient,
                public msalService: MsalService) {
        super(http, msalService);
    }

    getInstructionList(pageNumber: number, pageSize: number, status: string, localeId: any, accountId: string) {
        return this.get<InstructionListGetResponse>
        (`${this.BASE_URL}/?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&localeId=${localeId}&accountId=${accountId}`);
    }

    getInstruction(instructionId: string) {
        return this.get<Instruction>
            (`${this.BASE_URL}/${instructionId}`);
    }

    getInstructionStepList(instructionId: string) {
        return this.get<InstructionStepGetResponse>
            (`${this.BASE_URL}/${instructionId}/steps`);
    }

    createInstructionStep(instructionId: string, request: InstructionStepCreateRequest) {
        super.setAuthData(request);
        return this.post<InstructionStepCreateResponse>
            (`${this.BASE_URL}/${instructionId}/steps`, request);
    }

    updateInstructionStep(instructionId: string, stepId: string, request: InstructionStepUpdateRequest) {
        super.setAuthData(request);
        return this.put
            (`${this.BASE_URL}/${instructionId}/steps/${stepId}`, request);
    }


    deleteInstructionStep(instructionId: string, stepId: string) {
        return this.delete<InstructionStepCreateResponse>
            (`${this.BASE_URL}/${instructionId}/steps/${stepId}`);
    }

    createInstruction(request: InstructionCreateRequest) {
        super.setAuthData(request);
        return this.post<InstructionCreateResponse>
            (`${this.BASE_URL}`, request);
    }

    updateInstruction(instructionId: string, request: InstructionUpdateRequest) {
        super.setAuthData(request);
        return this.put
            (`${this.BASE_URL}/${instructionId}`, request);
    }

    deleteInstruction() {
        throw new Error('Method not implemented.');
    }

    createInstructionProduct(instructionId: string, request: InstructionProductCreateRequest) {
        // super.setAuthData(request);
        return this.post
            (`${this.BASE_URL}/${instructionId}/products`, request);
    }

    updateInstructionProduct(instructionId: string, request: InstructionProductUpdateRequest) {
        // super.setAuthData(request);
        return this.put
            (`${this.BASE_URL}/${instructionId}/products/${request.productId}`, request);
    }

    deleteInstructionProduct(instructionId: string, productId: string) {
        return this.delete
            (`${this.BASE_URL}/${instructionId}/products/${productId}`);
    }

    getInstructionStep() {
        throw new Error('Method not implemented.');
    }

    createInstructionStepSupply(instructionId: string, stepId: string, supplyId: string, request: InstructionStepSupplyCreateRequest) {
        // super.setAuthData(request);
        return this.post
            (`${this.BASE_URL}/${instructionId}/steps/${stepId}/supplies/${supplyId}`, request);
    }

    updateInstructionStepSupply(instructionId: string, stepId: string, supplyId: string, request: InstructionStepSupplyUpdateRequest) {
        // super.setAuthData(request);
        return this.put
            (`${this.BASE_URL}/${instructionId}/steps/${stepId}/supplies/${supplyId}`, request);
    }

    deleteInstructionStepSupply(instructionId: string, stepId: string, supplyId: string) {
        return this.delete
            (`${this.BASE_URL}/${instructionId}/steps/${stepId}/supplies/${supplyId}`);
    }

    getInstructionStepMediaList() {
        throw new Error('Method not implemented.');
    }

    getInstructionStepMedia() {
        throw new Error('Method not implemented.');
    }

    createInstructionStepMedia() {
        // super.setAuthData(request);
        throw new Error('Method not implemented.');
    }

    updateInstructionStepMedia() {
        // super.setAuthData(request);
        throw new Error('Method not implemented.');
    }
}
