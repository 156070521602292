import { AuthConfig } from 'angular-oauth2-oidc';
import { MvpApiServiceStub } from 'src/app/modules/mvp/services/mvp/stub/mvp-api-service';
import { OrderLineApiServiceStub } from 'src/app/modules/order-line/services/order-line/stub/order-line-api-service';
import { PackingInstructionsApiServiceStub } from 'src/app/modules/packing-instructions/services/packing-instructions/stub/packing-instructions-api-service';
import { ProblemOrderApiServiceStub } from 'src/app/modules/problem-order/services/problem-order/stub/problem-order-api-service';
import { RequiredSuppliesLineApiServiceStub } from 'src/app/modules/required-supplies-line/services/required-supplies-line/stub/required-supplies-line-api-service';
import { ScaleApiServiceStub } from 'src/app/modules/scale/services/scale/stub/scale-api-service';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ScanningOrderApiServiceStub } from 'src/app/modules/scanning-order/services/scanning-order/stub/scanning-order-api-service';
import { SetupApiServiceStub } from 'src/app/modules/setup/services/setup/stub/setup-api-service';

export const environment = {
  production: false,
  packApiUrl: 'https://rushmore.owd.com/api/dev/v1.0',
  packApiUrlv1_1: 'https://rushmore.owd.com/api/dev/v1.1',
  packApiKey: '786f74459e9d46008d174fb52fcafe34',
  shippingApiUrl: 'http://dev.shippingapi.owd.com/v1.1/proship',
  shippingApiConnect: 'https://identity.owd.com/connect/token',
  shippingApiClientId: '7701e175c636477bb90e9d81b45a39c5',
  shippingApiClientSecret: '81357175594843008d5590eae280df5b',
  shippingApiScope: 'owdShippingAPI',
  billingApiUrl: 'https://oms.boxture.com/billing',
  locationId: '1062e3b5-859f-41b5-b73e-b8f2e023ac5c',
  locationName: 'DC6',
  stationId: '12345678-1234-1234-1234-7341ae714b52',
  noBoxId: '9ee01e04-f345-4eaa-b1cb-1f8920e694b0',
  customBoxId: '6d4b03e1-770b-449f-952c-4cc31ee4e010',
  proShipMulticarrierId: '3a71db4d-db58-453a-af56-9e074eb9b07c',
  billingGroupCode: 'OMS',
  billingSourceCode: 'DC1-NORTHCENTRAL',
  hmr: false,
  appInsights: {
    instrumentationKey: '<your-guid>'
  },
  chromeExtId: 'kmkjkmflpcmcpocidbdcmbjdjbcgjenb',
  scanningOrderApiService: ScanningOrderApiServiceStub,
  mvpApiService: MvpApiServiceStub,
  problemOrderApiService: ProblemOrderApiServiceStub,
  orderLineApiService: OrderLineApiServiceStub,
  requiredSuppliesLineApiService: RequiredSuppliesLineApiServiceStub,
  packingInstructionsApiService: PackingInstructionsApiServiceStub,
  setupService: SetupApiServiceStub,
  scaleService: ScaleApiServiceStub
};
