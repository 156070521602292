<div class="left-content" [ngClass]="{'sidebar-closed': isSidebarClosed}" *ngIf="steps">
    <div class="items">
        <div class="items-header">
            <div class="left">
                Required supplies
            </div>
            <div class="right">
                {{ getSelectedStepSupplies()?.length }}
            </div>
        </div>
        <div class="product-items">
            <!-- <div class="product low-inventory">
                <div class="product-info">
                    <div class="left image">
                        <img src="../../../../assets/images/supply55x70.png">
                    </div>
                    <div class="left info">
                        <div class="title">
                            Item name goes here
                        </div>
                        <div class="description">
                            Description text goes here
                        </div>
                        <div class="item-description">
                            <div class="item-params">
                                <span class="quantity">3</span>
                                <span class="uom">{{ 'item-params.pcs' | translate }}</span>
                                <span class="caption separator">{{ 'item-params.required' | translate }}</span>
                                <span class="quantity">3</span>
                                <span class="uom">{{ 'item-params.pcs' | translate }}</span>
                                <app-status class="status" [quantity]="3"></app-status>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="change-supply">
                    <button>Change Supply</button>
                </div>
                <div class="qr-code">
                    <qrcode [qrdata]="'Your data string'" [width]="70" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div>
            <div class="product">
                <div class="product-info">
                    <div class="left image">
                        <img src="../../../../assets/images/supply55x70.png">
                    </div>
                    <div class="left info">
                        <div class="title">
                            Item name goes here
                        </div>
                        <div class="description">
                            Description text goes here
                        </div>
                        <div class="item-description">
                            <div class="item-params">
                                <span class="quantity">12</span>
                                <span class="uom">{{ 'item-params.pcs' | translate }}</span>
                                <span class="caption separator">{{ 'item-params.required' | translate }}</span>
                                <app-status class="status" [quantity]="12"></app-status>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="edit-quantity">
                    <div class="remove left">
                        <button></button>
                    </div>
                    <div class="quantity">
                        1
                    </div>
                    <div class="add right">
                        <button></button>
                    </div>
                </div>
                <div class="qr-code">
                    <qrcode [qrdata]="'Your data string'" [width]="70" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div> -->
            <div class="product" *ngFor="let supply of getSelectedStepSupplies()">
                <div class="product-info">
                    <div class="left image">
                        <img src="../../../../assets/images/supply55x70.png">
                    </div>
                    <div class="left info">
                        <div class="title">
                            {{ supply.name }}
                        </div>
                        <div class="description">
                            {{ supply.description | striphtml }}
                        </div>
                        <div class="item-description">
                            <div class="item-params">
                                <span class="quantity">{{ supply.quantity }}</span>
                                <span class="uom">{{ 'item-params.pcs' | translate }}</span>
                                <span class="caption separator">{{ 'item-params.required' | translate }}</span>
                                <app-status class="status" [quantity]="supply.quantity"></app-status>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="qr-code">
                    <qrcode [qrdata]="supply.id" [width]="70" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div>
            <!-- <div class="product out-of-stock">
                <div class="product-info">
                    <div class="left image">
                        <img src="../../../../assets/images/supply55x70.png">
                    </div>
                    <div class="left info">
                        <div class="title">
                            Item name goes here
                        </div>
                        <div class="description">
                            Description text goes here
                        </div>
                        <div class="item-description">
                            <div class="item-params">
                                <span class="quantity">2</span>
                                <span class="uom">{{ 'item-params.pcs' | translate }}</span>
                                <span class="caption separator">{{ 'item-params.required' | translate }}</span>
                                <span class="quantity">0</span>
                                <span class="uom">{{ 'item-params.pcs' | translate }}</span>
                                <app-status class="status" [quantity]="0"></app-status>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="change-supply">
                    <button>Change Supply</button>
                </div>
            </div> -->
        </div>
        <div class="footer">
            <a class="minimize" (click)="triggerSideBar()">Minimize</a>
        </div>
    </div>
    <div class="sidebar">
        <a class="sidebar-title" (click)="triggerSideBar()">View Product</a>
        <div class="footer">
            <a class="maximize" (click)="triggerSideBar()"></a>
        </div>
    </div>
</div>
<div class="right-content" [ngClass]="{'sidebar-closed': isSidebarClosed}" *ngIf="steps">
    <div class="video-container"  *ngIf="selectedMenuItem?.linkStatus === LinkStatus.Selected">
        <div class="video" *ngIf="selectedMenuItem.imageUrl">
            <img [src]="selectedMenuItem.imageUrl">
        </div>
        <div class="video" *ngIf="selectedMenuItem.videoUrl">
            <video [autoplay]="true" (timeupdate)="onTimeUpdate($event)" width="953" height="560" #video>
                <source [src]="selectedMenuItem.videoUrl" type="video/mp4" />
                Browser not supported
            </video>
        </div>
        <div class="videoDescription" *ngIf="selectedMenuItem.videoUrl || selectedMenuItem.imageUrl">
            {{ selectedMenuItem.text }}
        </div>
        <div class="description" *ngIf="!selectedMenuItem.videoUrl && !selectedMenuItem.imageUrl">
            {{ selectedMenuItem.text }}
        </div>
    </div>
</div>