import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { IRequiredSuppliesLineApiService } from '../required-supplies-line.api.interface';


@Injectable()
export class RequiredSuppliesLineApiServiceStub implements IRequiredSuppliesLineApiService {
    constructor() {

    }

    getBox() {
        throw new Error('Method not implemented.');
    }
}
