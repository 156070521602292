import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FooterDisplayMode, FooterMenuItem, FooterMenuItemType, FooterService, LinkStatus } from '../../services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, AfterViewInit {
  leftMenuItems: FooterMenuItem[];
  middleMenuItems: FooterMenuItem[];
  rightMenuItems: FooterMenuItem[];
  title: string;
  displayMode: FooterDisplayMode;

  progress = 0;

  public get FooterMenuItemType(): typeof FooterMenuItemType {
    return FooterMenuItemType;
  }

  public get LinkStatus(): typeof LinkStatus {
    return LinkStatus;
  }

  public get DisplayMode(): typeof FooterDisplayMode {
    return FooterDisplayMode;
}

  constructor(private footerService: FooterService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.footerService.onVideoProgressChanged()
    .subscribe(x => {
        this.progress = Math.floor((x.progress / x.duration) * 100);
    });

    this.footerService.onSetFooter()
      .subscribe(footer => {
          this.leftMenuItems = footer.leftMenuItems;
          this.middleMenuItems = footer.middleMenuItems;
          this.rightMenuItems = footer.rightMenuItems;
          this.displayMode = footer.displayMode;
          this.title = footer.title;
      });
  }

  clickDefaultButton(buttonId: string) {
    this.footerService.clickDefaultButton(buttonId);
  }
}
