import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SetupMode } from 'src/app/shared-kernel/enums/setup-mode.enum';
import { LocaleApiService } from 'src/app/shared-kernel/services/locale/locale.api.service';
import { AuthService } from '../../services';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, AfterViewInit {
    form: FormGroup;

    constructor(private authService: AuthService,
                private router: Router,
                private cookieService: CookieService,
                private localeApiService: LocaleApiService,
                public fb: FormBuilder) { }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {

    }
}
