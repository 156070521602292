<div class="comm-invoice" style="width: 21cm;height: 27.7cm;margin: 0 20px;display: none; position: relative;" id="comm-invoice-print">
  <div class="separator"></div>
  <div class="title">
    <h1>Commercial Invoice</h1>
  </div>
  <div class="separator"></div>
  <div class="sub-title">
    <div class="column">
      <div class="column">
        Date Shipped:
      </div>
      <div class="column">
        {{ this.shippedDate }}
      </div>
    </div>
    <div class="column">
      <div class="column">
        Invoice:
      </div>
      <div class="column">
        #{{ this.order.id.slice(0,8) }}
      </div>
    </div>
  </div>
  <div class="border">
    <div class="separator gray"></div>
  </div>
  <div class="address">
    <div class="column">
      <div class="header">Shipper Name/Address</div>
      <div>TAX ID: 46-0458580</div>
      <div>One World Direct</div>
      <div>1915 10th Ave W</div>
      <div>Mobridge, SD 57601</div>
      <div>USA</div>
      <br/>
      <div>Phone: 605-845-7172</div>
      <div>Reason for export: Sale</div>
    </div>
    <div class="column">
      <div class="header">Buyer Name and Address</div>
      <div *ngIf="this.billingAddress?.companyName">{{ this.billingAddress?.companyName }}</div>
      <div *ngIf="this.billingAddress?.firstName || this.billingAddress?.lastName">{{ this.billingAddress?.firstName }}  {{this.billingAddress?.lastName }}</div>
      <div *ngIf="this.billingAddress?.addressLines">{{ this.billingAddress?.addressLines[0]}}</div>
      <div>
        <span *ngIf="this.billingAddress?.addressCity">{{ this.billingAddress?.addressCity }},</span> 
        <span *ngIf="this.billingAddress?.addressStateOrProvinceCode"> {{ this.billingAddress?.addressStateOrProvinceCode }}</span>
        <span *ngIf="this.billingAddress?.addressPostalCode">{{ this.billingAddress?.addressPostalCode }}</span>
      </div>
      <div *ngIf="this.billingAddress?.addressCountryCode">{{ this.billingAddress?.addressCountryCode }}</div>
      <div *ngIf="this.billingAddress?.phoneNumber">Phone: {{ this.billingAddress?.phoneNumber }}</div>
      <br/>
     
      <div *ngIf="this.order?.customerReferenceNumber">Billing Ref: {{ this.order.customerReferenceNumber }}</div>
      <br/>
      <div> BOL/Waybill: </div>
      <div *ngFor="let parcel of this.shipment.parcels;">
        {{ parcel.trackingNumber }}
      </div>
    </div>
    <div class="column">
      <div class="header">Consignee Name and Address</div>
      <div *ngIf="this.shippingAddress?.companyName">{{ this.shippingAddress?.companyName }}</div>
      <div *ngIf="this.shippingAddress?.firstName || this.shippingAddress?.lastName">{{ this.shippingAddress?.firstName }}  {{this.shippingAddress?.lastName }}</div>
      <div *ngIf="this.shippingAddress?.addressLines">{{ this.shippingAddress?.addressLines[0]}}</div>
      <div>
        <span *ngIf="this.shippingAddress?.addressCity">{{ this.shippingAddress?.addressCity }},</span> 
        <span *ngIf="this.shippingAddress?.addressStateOrProvinceCode"> {{ this.shippingAddress?.addressStateOrProvinceCode }}</span>
        <span *ngIf="this.shippingAddress?.addressPostalCode">{{ this.shippingAddress?.addressPostalCode }}</span>
      </div>
      <div *ngIf="this.shippingAddress?.addressCountryCode">{{ this.shippingAddress?.addressCountryCode }}</div>
      <div *ngIf="this.shippingAddress?.phoneNumber">Phone: {{ this.shippingAddress?.phoneNumber }}</div>
    </div>
  </div>
  <div class="details">
    <div class="row header">
      <div class="column width10percent">
        Units
      </div>
      <div class="column width60percent">
        Complete Description of Goods
      </div>
      <div class="column width10percent">
        <div class="right">
          Unit Value
        </div>
      </div>
      <div class="column width20percent">
        <div class="right">
          Total
        </div>
      </div>
    </div>
    <div *ngFor="let shippedProduct of this.shippedProducts;">
    <div class="row">
      <div class="column width10percent">
        {{ shippedProduct.productQuantity }}
      </div>
      <div class="column width60percent description">
        <span *ngIf="shippedProduct.productOrigin">Origin: {{ shippedProduct.productOrigin}} / </span>{{ shippedProduct.productName }}
      </div>
      <div class="column width10percent">
        <div class="right">
          <span *ngIf="shippedProduct.productUnitPrice">${{ shippedProduct.productUnitPrice }}</span>
        </div>
      </div>
      <div class="column width20percent">
        <div class="right">
          <span *ngIf="shippedProduct.productPrice">${{ shippedProduct.productPrice }}</span>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="footer">
    <div class="width55percent left">
      <div class="content">
        These commodities, technology or software were exported from the United States of
        America in accordance with the Export Administration Regulations. Diversion
        contrary to U.S. law prohibited.
      </div>
      <div class="signature-form">
        <div class="caption left">
          <span>x</span>
        </div>
        <div class="signature left">
          
        </div>
      </div>
      <div class="date">
        <br/>
        Date: {{ this.shippedDate }}
      </div>
    </div>
    <div class="width45percent left">
      <div>

      </div>
      <div class="footer-details">
        <div class="row header">
          <div class="column width60percent">
            Subtotal Value
          </div>
          <div class="column width40percent">
            $ {{ this.getSubtotal().toFixed(2) }}
          </div>
        </div>
        <div class="row">
          <div class="column width60percent">
            Discount
          </div>
          <div class="column width40percent">
            $ {{ this.getTotalDiscount().toFixed(2) }}
          </div>
        </div>
        <div class="row">
          <div class="column width60percent">
            Total Value
          </div>
          <div class="column width40percent">
            $ {{ this.getTotal().toFixed(2) }}
          </div>
        </div>
        <div class="row header">
          <div class="column width60percent">
            Total Weight (Lbs):
          </div>
          <div class="column width40percent">
            {{ this.totalWeight }}
          </div>
        </div>
        <div class="row header">
          <div class="column width60percent">
            Total Packages:
          </div>
          <div class="column width40percent">
            {{ this.shipment.parcels.length }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
